import { AudioPill } from './AudioPill';
import { BigTitleOption } from './BigTitleOption';
import { Colours } from '../../components/Colours';
import {
  DEFAULT_DIMENSIONS,
  horizontalSpaceMultiplier
} from '../utils/dimension';
import { DefaultOption } from './DefaultOption';
import { FONT_FAMILY } from '../utils/constant';
import { Footer } from '../components/Footer';
import { GenerateVideoParams } from '../Video';
import { Sequence } from 'remotion';
import { SubtitleItem } from '../components/Subtitles';
import { SubtitleOptions } from '../../types/MediaManager';
import { TextOutput } from '../components/TextSequence';
import { WordByWordOption } from './WordByWordOption';
import {
  createSpotifySubtitleArray,
  useCalculateOpacity
} from '../utils/general';
import { getHexCodeFromColourName } from '../../utils/colour';
import AudioContainer from '../components/AudioContainer';
import React, { FunctionComponent } from 'react';

export const DefaultRender: FunctionComponent<any> = (
  props: GenerateVideoParams
) => {
  const {
    title,
    audioUrl,
    avatarUrl,
    subtitles,
    subtitlesContent,
    handle,
    color,
    textColor,
    dimensionType,
    hidePromotion,
    font
  } = props;
  const hexCode = getHexCodeFromColourName(color);
  const isDefault = hexCode === Colours.White;

  const hexCodeTextColor = getHexCodeFromColourName(textColor);

  let collatedSubs: TextOutput[][] = [];
  if (subtitles === SubtitleOptions.BIG_TITLE)
    collatedSubs = createSpotifySubtitleArray(subtitlesContent as TextOutput[]);

  const getSelectedSubtitleConfiguration = () => {
    switch (subtitles) {
      case SubtitleOptions.CC:
      case SubtitleOptions.NONE:
        return (
          <DefaultOption
            subtitlesContent={subtitlesContent as TextOutput[]}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
      case SubtitleOptions.BIG_TITLE:
        return (
          <BigTitleOption
            collatedSubs={collatedSubs}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
      case SubtitleOptions.WORD_BY_WORD:
        return (
          <WordByWordOption
            title={title}
            color={hexCodeTextColor}
            subtitlesContent={subtitlesContent as SubtitleItem[]}
            font={font}
          />
        );
      default:
        return (
          <DefaultOption
            subtitlesContent={subtitlesContent as TextOutput[]}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
    }
  };

  return (
    <div
      style={{
        background: hexCode,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          flex: 1,
          height: DEFAULT_DIMENSIONS.H,
          fontFamily: FONT_FAMILY(font),
          paddingTop: 94,
          paddingBottom: 64,
          opacity: useCalculateOpacity()
        }}
      >
        <div
          style={{
            height: DEFAULT_DIMENSIONS.H,
            paddingLeft: 48 * horizontalSpaceMultiplier(dimensionType),
            paddingRight: 48 * horizontalSpaceMultiplier(dimensionType)
          }}
        >
          <AudioContainer from={0} audioUrl={audioUrl} />
          <div
            style={{
              height: '60%',
              display: 'flex',
              alignItems: 'flex-start',
              maxWidth: 1552
            }}
          >
            {getSelectedSubtitleConfiguration()}
          </div>
          <div style={{ height: '33%' }}>
            <Sequence from={0} layout="none">
              <AudioPill
                audioUrl={audioUrl}
                avatarUrl={avatarUrl}
                isDefault={isDefault}
              />
            </Sequence>
          </div>
        </div>
      </div>
      <Footer
        horizontalPadding={48 * horizontalSpaceMultiplier(dimensionType)}
        handle={handle}
        hidePromotion={hidePromotion}
        color={hexCodeTextColor}
      />
    </div>
  );
};
