import { StandardIconProps } from '../../../components/Shared/Icon';

const dropdown_open = (props: StandardIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16898 9.00854L12.0389 12.8784L15.9088 9.00854C16.2978 8.61955 16.9261 8.61955 17.3151 9.00854C17.7041 9.39752 17.7041 10.0259 17.3151 10.4149L12.7371 14.9929C12.3481 15.3819 11.7197 15.3819 11.3307 14.9929L6.75268 10.4149C6.36369 10.0259 6.36369 9.39752 6.75268 9.00854C7.14166 8.62952 7.77999 8.61955 8.16898 9.00854Z"
        fill={props.fill || 'white'}
      />
    </svg>
  );
};

export default dropdown_open;
