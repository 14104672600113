import { AudioVisualization } from '../components/AudioVisualization';
import { BigTitleOption } from './BigTitleOption';
import { Colours } from '../../components/Colours';
import {
  DEFAULT_DIMENSIONS,
  DimensionType,
  horizontalSpaceMultiplier
} from '../utils/dimension';
import { DefaultOption } from './DefaultOption';
import { FONT_FAMILY } from '../utils/constant';
import { Footer } from '../components/Footer';
import { GenerateVideoParams } from '../Video';
import { SubtitleItem } from '../components/Subtitles';
import { SubtitleOptions } from '../../types/MediaManager';
import { TextOutput } from '../components/TextSequence';
import { WordByWordOption } from './WordByWordOption';
import {
  createSpotifySubtitleArray,
  useCalculateOpacity
} from '../utils/general';
import { getHexCodeFromColourName } from '../../utils/colour';
import AudioContainer from '../components/AudioContainer';
import React, { FunctionComponent } from 'react';

export const FullSizeRender: FunctionComponent<any> = (
  props: GenerateVideoParams
) => {
  const {
    title,
    audioUrl,
    // avatarUrl,
    bannerUrl,
    subtitles,
    subtitlesContent,
    handle,
    // color,
    textColor,
    dimensionType,
    hidePromotion,
    font
  } = props;
  const hexCodeTextColor = getHexCodeFromColourName(textColor);

  let collatedSubs: TextOutput[][] = [];
  if (subtitles === SubtitleOptions.BIG_TITLE)
    collatedSubs = createSpotifySubtitleArray(subtitlesContent as TextOutput[]);

  const getSelectedSubtitleConfiguration = () => {
    switch (subtitles) {
      case SubtitleOptions.CC:
      case SubtitleOptions.NONE:
        return (
          <DefaultOption
            color={hexCodeTextColor}
            title={title}
            font={font}
            subtitlesContent={subtitlesContent as TextOutput[]}
          />
        );
      case SubtitleOptions.BIG_TITLE:
        return (
          <BigTitleOption
            title={title}
            collatedSubs={collatedSubs}
            font={font}
            color={hexCodeTextColor}
          />
        );
      case SubtitleOptions.WORD_BY_WORD:
        return (
          <WordByWordOption
            title={title}
            subtitlesContent={subtitlesContent as SubtitleItem[]}
            font={font}
            color={hexCodeTextColor}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        // TODO check if this works because it might not render since I might have to use the Remotion <Image/> component
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${bannerUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <div
        style={{
          flex: 1,
          opacity: useCalculateOpacity()
        }}
      >
        <AudioContainer from={0} audioUrl={audioUrl} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            fontFamily: FONT_FAMILY(font),
            position: 'relative',
            height: DEFAULT_DIMENSIONS.H
          }}
        >
          <div
            style={{
              paddingLeft: 48 * horizontalSpaceMultiplier(dimensionType),
              paddingRight: 48 * horizontalSpaceMultiplier(dimensionType)
            }}
          >
            <AudioVisualization
              audioUrl={audioUrl}
              waveColor={hexCodeTextColor}
              isDefault={null}
              style={{ marginLeft: 150, height: 200 }}
            />
            <div style={{ height: 420, maxWidth: 1152 }}>
              {getSelectedSubtitleConfiguration()}
            </div>
          </div>
        </div>
        <Footer
          horizontalPadding={48 * horizontalSpaceMultiplier(dimensionType)}
          handle={handle}
          hidePromotion={hidePromotion}
          color={hexCodeTextColor}
        />
      </div>
    </div>
  );
};
