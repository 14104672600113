import { NewJPButton } from '../GeneralComponents';
import { gaCheckout } from '@/lib/GoogleAnalytics';
import { plans } from '@/models/plans';
import { toast } from 'react-toastify';
import { useModal } from '@/hooks/useModal';
import { useState } from 'react';
import CreatorPlanDetailsImage from '../../assets/images/icons/creatorPlanDetailsImage.svg';
import Icon, { IconType } from '../Shared/Icon';
import Image from 'next/image';
import Link from 'next/link';
import User from '@/models/User';

export const UpgradeSubscriptionModal = () => {
  const [activePricingOption, setActivePricingOption] = useState('annually');
  const { closeModal } = useModal();
  const toggleActivePricingOption = () => {
    setActivePricingOption(
      activePricingOption === 'annually' ? 'monthly' : 'annually'
    );
  };
  const onUpgrade = async () => {
    console.log('upgrade');
    const creatorPlan = plans.find((plan) => plan.name === 'Creator');
    gaCheckout(creatorPlan);

    const stripeResponse = await User.getStripeCheckoutSessionWithDiscountCode(
      creatorPlan.name,
      activePricingOption === 'annually'
    );
    const stripeCheckoutURL = stripeResponse.session_url;
    if (stripeCheckoutURL) return (window.location.href = stripeCheckoutURL);
    toast.error(
      'There is something wrong on our server. Please try again later.'
    );
  };
  return (
    <div className="rounded-lg border border-solid border-gray-200 bg-white shadow">
      <div className="flex flex-col items-start gap-4 p-6">
        <Icon type={IconType.BlazeIcon} size={40} />
        <div>
          <p className="text-2xl font-500">Create more, worry less.</p>
          <p className="text-2xl font-500">Upgrade your plan today.</p>
        </div>
        <div>
          <Image
            src={CreatorPlanDetailsImage}
            alt="updgrade subscription home page button"
          />
        </div>
        <div>
          <p className="text-xs text-gray-400">
            Want something different?{' '}
            <Link
              href="/pricing"
              target="_blank"
              onClick={closeModal}
              className=" text-gray-400 underline"
            >
              Explore Plans
            </Link>
          </p>
        </div>
        <div className="flex w-full gap-2">
          <div
            onClick={toggleActivePricingOption}
            className={`flex-1 cursor-pointer rounded border border-solid p-2 ${
              activePricingOption === 'annually'
                ? 'bg-orange-50 text-orange-500'
                : 'border-gray-300 bg-gray-50 text-gray-500'
            }`}
          >
            <span className="flex items-center">
              <p className="text-lg font-bold">$17</p>
              <p className="text-xs font-400"> / month</p>
            </span>
            <span className="flex justify-between">
              <p className="text-xs font-400">Paying anually</p>
              <p className="text-xs font-medium">Save $60</p>
            </span>
          </div>
          <div
            onClick={toggleActivePricingOption}
            className={`flex-1 cursor-pointer rounded border-solid p-2 ${
              activePricingOption === 'monthly'
                ? 'border bg-orange-50 text-orange-500'
                : 'bg-gray-50 text-gray-500'
            }`}
          >
            <span className="flex items-center">
              <p className="text-lg font-bold">$22</p>
              <p className="font-400"> / month</p>
            </span>
            <span className="flex">
              <p className="text-xs font-400">Paying monthly</p>
            </span>
          </div>
        </div>

        <NewJPButton
          text="Upgrade"
          colorType="orange"
          size="sm"
          className="w-full items-center justify-center"
          onClick={onUpgrade}
        />
      </div>
    </div>
  );
};
