import { BigTitleSequence } from '../components/BigTitleSequence';
import { FONT_FAMILY, VideoTemplateFonts } from '../utils/constant';
import { TextOutput } from '../components/TextSequence';
import React from 'react';

export const BigTitleOption: React.FC<{
  collatedSubs: TextOutput[][];
  color: string;
  font: VideoTemplateFonts;
}> = ({ color, collatedSubs, font }) => (
  <BigTitleSequence
    collatedSubs={collatedSubs}
    color={color}
    style={{ width: '100%', fontFamily: FONT_FAMILY(font) }}
  />
);
