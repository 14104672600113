import { StandardIconProps } from '../../../components/Shared/Icon';

const dropdown_close = (props: StandardIconProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16898 14.9934L12.0389 11.1235L15.9088 14.9934C16.2978 15.3824 16.9261 15.3824 17.3151 14.9934C17.7041 14.6044 17.7041 13.9761 17.3151 13.5871L12.7371 9.00904C12.3481 8.62006 11.7197 8.62006 11.3307 9.00904L6.75268 13.5871C6.36369 13.9761 6.36369 14.6044 6.75268 14.9934C7.14166 15.3724 7.77999 15.3824 8.16898 14.9934Z"
        fill={props.fill || 'white'}
      />
    </svg>
  );
};
export default dropdown_close;
