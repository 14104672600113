import { API_URL } from './constant';
import { Audio } from './Audio';
import { LocalStorageItems } from '@/utils/generalUtilities';
import Stripe from 'stripe';
import axios, { AxiosResponse } from 'axios';
import fetchWrapper, {
  FetchConfig,
  FetchError,
  MethodType
} from '../utils/fetchWrapper';

export type premiumPermission = {
  total_video_seconds: number;
  remaining_video_seconds: number;
  total_transcription_seconds?: number;
  remaining_transcription_seconds?: number;
  last_updated: Date;
  cycle_start_date: Date;
};

type AppsumoTiers = 'jupitrr_tier1' | 'jupitrr_tier2' | 'jupitrr_tier3';

export type premiumMongo = premiumStripeMongo | premiumAppsumoMongo;

export type premiumAppsumoMongo = {
  source: 'appsumo';
  user_id: string;
  price_id: AppsumoTiers;
  product_id: AppsumoTiers;
  plan_name: AppsumoTiers;
  last_updated: Date;
  invoice_item_uuid?: string;
};

type premiumStripeMongo = (Stripe.Subscription | Stripe.Checkout.Session) & {
  source?: 'stripe';
  user_id: string;
  price_id: string;
  product_id: string;
  plan_name: string | null;
  last_updated: Date;
};

export interface UserInfo {
  id: string;
  url_slug: string;
  email: string;
  display_name: string;
  avatar: string;
  bio?: string;
  audios: Audio[];
  premium_permissions?: premiumPermission;
  current_premium?: premiumMongo;
  stripe_customer_id?: string;
  appsumo_license_uuid?: string;
  created_at: string;
  last_updated: string;
}

const User = {
  name: '/users',
  route: '',

  async getUserInfo(
    uid_or_url_slug: string,
    token?: string
  ): Promise<UserInfo> {
    this.route = `${API_URL + this.name}/${uid_or_url_slug}`;
    const data = await fetchWrapper(this.route, MethodType.GET, null, token);
    return data as UserInfo;
  },

  me(token: string, fetchConfig?: FetchConfig): Promise<UserInfo & FetchError> {
    // UserInfo
    return new Promise((resolve, reject) => {
      this.route = `${API_URL}/me`;
      fetchWrapper(this.route, MethodType.GET, null, token, fetchConfig)
        .then((json: any) => {
          resolve(json);
        })
        .catch((error: Error) => {
          reject(error);
        });
    });
  },

  async getStripeBillingPortal(
    userId: string,
    token: string
  ): Promise<{ url: string }> {
    this.route = `${API_URL + this.name}/${userId}/premium-portal-session`;
    const data = await fetchWrapper(this.route, MethodType.GET, null, token);
    return data as { url: string };
  },

  async getStripeCheckoutSessionWithDiscountCode(
    planName: string,
    isAnnual: boolean
  ): Promise<{ session_url: string }> {
    const stripeResponse = (await fetchWrapper(
      `${
        API_URL + this.name
      }/create-checkout-session?planName=${planName}&interval=${
        isAnnual ? 'annual' : 'monthly'
      }`,
      MethodType.GET,
      null,
      localStorage.getItem(LocalStorageItems.ACCESS_TOKEN)
    )) as { session_url: string };

    return stripeResponse;
  },

  async update(token: string, uid: string, data: any): Promise<UserInfo> {
    this.route = `${API_URL + this.name}/${uid}`;
    const body = JSON.stringify(data);
    const res = await fetchWrapper(this.route, MethodType.PATCH, body, token);
    return res as UserInfo;
  },

  async delete(token: string, uid: string): Promise<any> {
    this.route = `${API_URL + this.name}/?id[]=${uid}`;
    const res = await fetchWrapper(this.route, MethodType.DELETE, null, token);
    return res;
  },

  async resetVideoPermission(
    token: string,
    uid: string,
    videoId: string,
    renderId: string
  ): Promise<{ success: boolean }> {
    this.route = renderId
      ? `${
          API_URL + this.name
        }/${uid}/reset-video-permissions/${videoId}--${renderId}`
      : `${API_URL + this.name}/${uid}/reset-video-permissions/${videoId}`;
    const res = await fetchWrapper(
      this.route,
      MethodType.PATCH,
      undefined,
      token
    );
    return res as { success: boolean };
  },

  /**
   * Routes to check if the `url_slug` is available
   * Does not require login
   * Returns if the URL slug is good or not
   * @param {string} url_slug - Required, e.g. `tsejerome`.
   * @returns {boolean} available - Whether the url_slug is available
   * @returns {string} slug_checked - the slug sent from frontend.
   */
  checkAvailableURL(url_slug: string): Promise<any> {
    return new Promise((resolve) => {
      const urlParams = new URLSearchParams();
      if (url_slug !== undefined) urlParams.append('url_slug', url_slug);
      this.route = `${API_URL + this.name}/url/check/?${urlParams.toString()}`;

      fetchWrapper(this.route, MethodType.GET)
        .then((json) => {
          resolve(json);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  }
};

export default User;
