import { IS_DEV_ENV } from '../models/constant';
import React from 'react';
import Script from 'next/script';
import locale from '../locales/en/NativeLanding';

export enum MetaTagKeys {
  TITLE = 'title',
  META_TITLE = 'meta_title',
  DESCRIPTION = 'description',
  OG_TYPE = 'og:type',
  OG_TITLE = 'og:title',
  OG_URL = 'og:url',
  OG_DESC = 'og:description',
  OG_IMG = 'og:image',
  OG_IMG_SECURE = 'og:image:secure_url',
  OG_SITE_NAME = 'og:site_name',
  TWITTER_CARD = 'twitter:card',
  TWITTER_CREATOR = 'twitter:creator',
  TWITTER_URL = 'twitter:url',
  TWITTER_SITE = 'twitter:site',
  TWITTER_TITLE = 'twitter:title',
  TWITTER_DESC = 'twitter:description',
  TWITTER_IMG = 'twitter:image',
  TWITTER_SUMMARY_LARGE_IMAGE = 'summary_large_image'
}

export const title = locale.seo.title;
export const description = locale.seo.meta_desc;
export const BASE_URL = 'https://jupitrr.com';
export const MAIN_EMAIL = 'hello@jupitrr.com';
export const INSTAGRAM_HANDLE = 'jupitrr.ai';
export const TWITTER_HANDLE = 'jupitrr_ai';
export const LINKEDIN_HANDLE = 'Jupitrr';

export const INSTAGRAM_LINK = `https://www.instagram.com/${INSTAGRAM_HANDLE}`;
export const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;
export const LINKEDIN_LINK = `https://www.linkedin.com/company/${LINKEDIN_HANDLE}`;

const DocumentHead = () => (
  <>
    {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
    <Script
      id={'google_tag_manager'}
      strategy={'afterInteractive'}
      src="https://www.googletagmanager.com/gtag/js?id=G-RJWMS5T360"
    />
    <Script id={'google_analytics'} strategy={'afterInteractive'}>
      {`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-RJWMS5T360');                    `}
    </Script>
    <Script id={'hotjar'} strategy={'afterInteractive'}>
      {`
                        (function(h,o,t,j,a,r){
                        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                        h._hjSettings={hjid:2009988,hjsv:6};
                        a=o.getElementsByTagName('head')[0];
                        r=o.createElement('script');r.async=1;
                        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
                    `}
    </Script>
    {!IS_DEV_ENV ? (
      <Script id={'google-tag-manager'} strategy={'afterInteractive'}>
        {`
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-N9FV29R');
    `}
      </Script>
    ) : null}

    {/* {!IS_DEV_ENV ? ( */}
    <Script id={'partnero'} strategy={'afterInteractive'}>
      {`
         (function(p,t,n,e,r,o){ p['__partnerObject']=r;function f(){
         var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
         f.q=f.q||[];p[r]=p[r]||f.bind(f.q);p[r].q=p[r].q||f.q;o=t.createElement(n);
         var _=t.getElementsByTagName(n)[0];o.async=1;o.src=e+'?v'+(~~(new Date().getTime()/1e6));
         _.parentNode.insertBefore(o,_);})(window, document, 'script', 'https://app.partnero.com/js/universal.js', 'po');
         po('settings', 'assets_host', 'https://assets.partnero.com');
         po('program', 'KH9QA7QT', 'load');
         po('integration', 'universal', null);
    `}
    </Script>
    {/* ) : null} */}
  </>
);

export default DocumentHead;
