import { normalFontSize } from '../utils/constant';
import React from 'react';

export const Title: React.FC<{
  text: string;
  color: string;
  style: any;
}> = ({ text, color, style }) => (
  <div
    style={Object.assign(
      {
        ...normalFontSize,
        fontSize: 64,
        color
      },
      style
    )}
  >
    {text}
  </div>
);
