import { Colours } from '../../components/Colours';
import { FONT_FAMILY } from '../utils/constant';
import { FPS } from '../Video';
import { Sequence } from 'remotion';
import React from 'react';

export interface TextOutput {
  c: string;
  f: string;
  t: string;
  content?: string;
  from?: number;
  to?: number;
}

export const TextSequence: React.FC<{
  text: TextOutput;
  layout?: 'absolute-fill' | 'none';
  style: React.CSSProperties;
}> = ({ text, layout, style }) => {
  const from = text.f !== undefined ? Number(text.f) : Number(text.from);
  const to = text.t !== undefined ? Number(text.t) : Number(text.to);
  const content = text.c !== undefined ? text.c : text.content;
  return (
    <Sequence
      layout={layout || 'absolute-fill'}
      from={Math.ceil(from) <= 0 ? 0 : Math.ceil(from * FPS)}
      durationInFrames={
        Math.ceil(to - from) <= 0 ? FPS : Math.ceil((to - from) * FPS)
      }
    >
      <div
        style={Object.assign(
          {
            textAlign: 'center',
            color: 'white',
            fontFamily: FONT_FAMILY()
          },
          style
        )}
      >
        {content.length > 0 ? (
          <span
            style={{
              backgroundColor: Colours.SubtitleBackground,
              borderRadius: 10,
              padding: 20,
              fontSize: 48,
              fontFamily: FONT_FAMILY()
            }}
          >
            {content}
          </span>
        ) : null}
      </div>
    </Sequence>
  );
};
