import { AudioVisualization } from '../components/AudioVisualization';
import { BigTitleOption } from './BigTitleOption';
import { Colours } from '../../components/Colours';
import {
  DEFAULT_DIMENSIONS,
  horizontalSpaceMultiplier
} from '../utils/dimension';
import { DefaultOption } from './DefaultOption';
import { FONT_FAMILY } from '../utils/constant';
import { Footer } from '../components/Footer';
import { GenerateVideoParams } from '../Video';
import { Img, Sequence } from 'remotion';
import { SubtitleItem } from '../components/Subtitles';
import { SubtitleOptions } from '../../types/MediaManager';
import { TextOutput, TextSequence } from '../components/TextSequence';
import { WordByWordOption } from './WordByWordOption';
import {
  createSpotifySubtitleArray,
  useCalculateOpacity
} from '../utils/general';
import { getHexCodeFromColourName } from '../../utils/colour';
import { imagerFormatter } from '../utils/imageFormatter';
import AudioContainer from '../components/AudioContainer';
import React, { FunctionComponent, useEffect, useState } from 'react';

const avatarDimensions = 190;

export const ProductHuntRender: FunctionComponent<any> = (
  props: GenerateVideoParams
) => {
  const {
    title,
    audioUrl,
    avatarUrl,
    subtitles,
    subtitlesContent,
    handle,
    color,
    textColor,
    dimensionType,
    hidePromotion,
    font
  } = props;

  const [avatar, setAvatar] = useState(
    avatarUrl ||
      'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
  );

  useEffect(() => {
    if (avatarUrl) setAvatar(avatarUrl);
  }, [avatarUrl]);

  const hexCode = getHexCodeFromColourName(color);
  const isDefault = hexCode === Colours.White;

  const hexCodeTextColor = getHexCodeFromColourName(textColor);
  const isDefaultTextColor = hexCodeTextColor === Colours.White;

  let collatedSubs: TextOutput[][] = [];
  if (subtitles === SubtitleOptions.BIG_TITLE)
    collatedSubs = createSpotifySubtitleArray(subtitlesContent as TextOutput[]);

  const getSelectedSubtitleConfiguration = () => {
    switch (subtitles) {
      case SubtitleOptions.CC:
      case SubtitleOptions.NONE:
        return (
          <DefaultOption
            title={title}
            color={hexCodeTextColor}
            isDefaultTextColor={isDefaultTextColor}
            font={font}
          />
        );
      case SubtitleOptions.BIG_TITLE:
        return (
          <BigTitleOption
            collatedSubs={collatedSubs}
            color={hexCodeTextColor}
            title={title}
            isDefaultTextColor={isDefaultTextColor}
            font={font}
          />
        );
      case SubtitleOptions.WORD_BY_WORD:
        return (
          <WordByWordOption
            subtitlesContent={subtitlesContent as SubtitleItem[]}
            color={hexCodeTextColor}
            title={title}
            isDefaultTextColor={isDefaultTextColor}
            font={font}
          />
        );
      default:
        return (
          <DefaultOption
            title={title}
            color={hexCodeTextColor}
            isDefaultTextColor={isDefaultTextColor}
            font={font}
          />
        );
    }
  };

  const cardHeight = 600;
  return (
    <div
      style={{
        background: hexCode,
        display: 'flex',
        width: '100%',
        height: '100%',
        alignItems: 'center'
      }}
    >
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: FONT_FAMILY(font),
          height: DEFAULT_DIMENSIONS.H,
          position: 'relative',
          opacity: useCalculateOpacity(),
          paddingLeft: 48 * horizontalSpaceMultiplier(dimensionType),
          paddingRight: 48 * horizontalSpaceMultiplier(dimensionType)
        }}
      >
        <AudioContainer from={0} audioUrl={audioUrl} />
        <Sequence from={0} layout="none">
          <div
            style={{
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 24,
              borderRadius: 54,
              backgroundColor: isDefault ? Colours.OffWhite : Colours.White,
              width: '100%',
              maxWidth: 977,
              height: cardHeight,
              paddingTop: 80
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: -96
              }}
            >
              <Img
                alt="avatar icon"
                style={{
                  objectFit: 'cover',
                  width: avatarDimensions,
                  height: avatarDimensions,
                  borderRadius: avatarDimensions / 2,
                  backgroundColor: 'white',
                  border: `8px solid ${Colours.White}`
                }}
                onError={() =>
                  setAvatar(
                    'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
                  )
                }
                src={
                  avatar ||
                  'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
                }
              />
            </div>
            <div
              style={{
                marginTop: 80,
                height: 250,
                display: 'flex',
                alignItems: 'flex-start'
              }}
            >
              {getSelectedSubtitleConfiguration()}
            </div>
            <AudioVisualization
              audioUrl={audioUrl}
              isDefault={true}
              waveColor={isDefaultTextColor ? Colours.Black : hexCodeTextColor}
              style={{
                height: 200
              }}
            />
            {subtitles === SubtitleOptions.CC ? (
              <div
                style={{
                  position: 'absolute',
                  bottom: -120
                }}
              >
                {subtitlesContent !== null &&
                (subtitlesContent as TextOutput[]).length > 0
                  ? (subtitlesContent as TextOutput[]).map((text, idx) => (
                    <TextSequence
                      key={idx}
                      text={text}
                      layout="none"
                      style={{ paddingBottom: 50 }}
                      />
                    ))
                  : null}
              </div>
            ) : null}
          </div>
        </Sequence>
      </div>
      <Footer
        handle={handle}
        color={hexCodeTextColor}
        hidePromotion={hidePromotion}
        horizontalPadding={48 * horizontalSpaceMultiplier(dimensionType)}
      />
    </div>
  );
};
