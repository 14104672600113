import { MediaManagerFS } from '../types/MediaManager';
import { collectionPath, firestore } from './firebase';
import _ from 'lodash';
import moment, { Moment } from 'moment';

interface FirestoreHelper {
  collectionPath: string;
  dataToUpdate: MediaManagerFS | null;
  queue: { [key: string]: QueueItem };
  updateFirestore: (
    this: FirestoreHelper,
    action: QueueItem,
    forceSave?: boolean
  ) => Promise<void>;
  removeRedundantData: (data: MediaManagerFS) => MediaManagerFS;
}

interface QueueItem {
  collectionPath: string;
  docId: string;
  data: MediaManagerFS;
  lastWriteTimestamp?: Moment;
  isDelayingForNextUpdate?: boolean;
}

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/*
 * Problem: @firebase/firestore: Firestore (8.10.0): Using maximum backoff delay to prevent overloading the backend.
 * Solution:
 * 1. Check if has last updated timestamp for the action, if no then update firestore directly and add the timestamp to the queue
 * 2. If has last updated timestamp, check if the last updated timestamp is less than 2 seconds, if yes then add this write to the queue and assign isDelayingForNextUpdate to true
 * 3. If has last updated timestamp, and if isDelayingForNextUpdate is true, then just update the data in the queue as we already have a timeout of 2 seconds, which will update the firestore afterwards
 *
 * Notes: foreSave is the high-level overriding to force update the firestore, this is used when the user clicks on the save button so we used loddash merge to merge the current data with the new data and update the firestore
 *
 * Assumptions: Newest data won't remove old data set
 */

const firestoreHelper: FirestoreHelper = {
  collectionPath: 'media_managers',
  dataToUpdate: null,
  queue: {},
  removeRedundantData: (dataSource: MediaManagerFS) => {
    const data = _.cloneDeep(dataSource);
    if (!data && !data.video_generation_status) return;
    Object.keys(data.video_generation_status).forEach((videoId) => {
      if (!data.video_generation_status[videoId].config) return;
      if (
        data.video_generation_status[videoId].config.avatar &&
        data.video_generation_status[videoId].config.avatar.startsWith('data:')
      )
        data.video_generation_status[videoId].config.avatar = null;
      if (
        data.video_generation_status[videoId].config.banner &&
        data.video_generation_status[videoId].config.banner.startsWith('data:')
      )
        data.video_generation_status[videoId].config.banner = null;
    });
    return data;
  },
  async updateFirestore(action, forceSave) {
    if (forceSave) {
      await timeout(2000);
      await firestore
        .collection(this.collectionPath)
        .doc(action.data.audio_id)
        .update(
          this.queue[action.docId]
            ? this.removeRedundantData(
                _.merge(this.queue[action.docId].data, action.data)
              )
            : this.removeRedundantData(action.data)
        );
      this.queue[action.docId] = {
        collectionPath,
        docId: action.docId,
        data: action.data,
        isDelayingForNextUpdate: false,
        lastWriteTimestamp: moment()
      };
      return;
    }

    /*
     * If there is a write in progress, add this write to the queue
     * Assumptions: Newest data won't remove old data set
     * */
    if (
      this.queue[action.docId] &&
      this.queue[action.docId].lastWriteTimestamp &&
      moment(
        moment().diff(this.queue[action.docId].lastWriteTimestamp)
      ).seconds() < 1
    ) {
      if (this.queue[action.docId].isDelayingForNextUpdate) {
        /* If has current queue then just update the data*/
        this.queue[action.docId].data = action.data;
        return;
      } else {
        /* Else update the data + delay for updates */
        this.queue[action.docId].data = action.data;
        this.queue[action.docId].isDelayingForNextUpdate = true;
        await timeout(2000);
        await this.updateFirestore(this.queue[action.docId]);
        return;
      }
    }

    // If there is no write in progress, set the action.data to update
    await firestore
      .collection(this.collectionPath)
      .doc(action.data.audio_id)
      .update(
        this.queue[action.docId]
          ? this.removeRedundantData(this.queue[action.docId].data)
          : this.removeRedundantData(action.data)
      );
    this.queue[action.docId] = {
      collectionPath,
      docId: action.docId,
      data: action.data,
      isDelayingForNextUpdate: false,
      lastWriteTimestamp: moment()
    };
  }
};

export default firestoreHelper;
