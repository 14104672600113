import { FetchError } from './fetchWrapper';
import {
  LocalStorageItems,
  PageNames,
  getItem,
  goBackToProfilePage,
  setItem
} from './generalUtilities';
import User, { UserInfo } from '../models/User';

/**
 * A Client side function for setting up redirection, in case user didn't finish setting up the profile
 *
 * @param {string} accessToken
 * @param {string} [finalDestination] If `finalDestination` === '', we won't redirect to any page, by default we will redirect to user's profile.
 * @param {(UserInfo & FetchError)} [userData] If the parent function has a userData, we can skip the extra retrieval for faster loading
 * @return {*}
 */
const clientSideProfileSetup = async (
  accessToken: string,
  finalDestination?: string
) => {
  let userInfo: UserInfo & FetchError;
  userInfo = await User.me(accessToken);
  // needed if logging in from a different device or incognito browser
  if (!userInfo || !userInfo.id) return;
  setItem(LocalStorageItems.URL_SLUG, userInfo.id);
  setItem(LocalStorageItems.DISPLAY_NAME, userInfo.display_name);
  if (!window) throw 'window not found';
  let dataToUpdate: any = {};

  if (!userInfo.display_name.length)
    dataToUpdate.display_name = dataToUpdate.email;
  if (Object.keys(dataToUpdate).length)
    await User.update(accessToken, userInfo.id, dataToUpdate);
  if (finalDestination === '') return;
  if (finalDestination) {
    return (window.location.href = finalDestination);
  }

  return goBackToProfilePage(userInfo.id);
};

export { clientSideProfileSetup };
