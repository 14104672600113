var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole } from '@sentry/integrations';
import { IS_DEV_ENV } from './models/constant';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  release: `${process.env.INTERNAL_NODE_ENV}-${process.env.VERCEL_GIT_COMMIT_SHA}`,
  environment: IS_DEV_ENV ? 'development' : 'production',
  dsn:
    SENTRY_DSN ||
    'https://62f8ca44adfb400d97f0060c98ce4560@o464788.ingest.sentry.io/6074809',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  integrations: [
    new CaptureConsole({
      levels: ['error']
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      maskAllInputs: false,
      block: ["input[type='password']"],
      blockAllMedia: false
    })
  ]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
