import { PODART_FONT_SIZE } from './PodArtRender';
import { normalFontSize } from '../utils/constant';
import React from 'react';

export const Title: React.FC<{
  text: string;
  color: string;
  style: any;
}> = ({ text, color, style }) => (
  <div
    style={Object.assign(
      {
        ...normalFontSize,
        width: '100%',
        height: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: PODART_FONT_SIZE,
        color,
        textAlign: 'center'
      },
      style
    )}
  >
    {text}
  </div>
);
