import { FONT_FAMILY, VideoTemplateFonts } from '../utils/constant';
import { Title } from './Title';
import React from 'react';

export const DefaultOption: React.FC<{
  title: string;
  color: string;
  isDefaultTextColor: boolean;
  font: VideoTemplateFonts;
}> = ({ title, color, isDefaultTextColor, font }) => (
  <Title
    style={{ fontFamily: FONT_FAMILY(font) }}
    text={title}
    color={color}
    isDefaultTextColor={isDefaultTextColor}
  />
);
