import { loadingSvg, loadingWhiteSvg } from '../assets/generalAssets';
import Image from 'next/image';
import React from 'react';

export enum LoadingColor {
  primary = 'primary',
  white = 'white',
}

const getImageSrc = (colorType) => {
  switch (colorType) {
    case LoadingColor.white:
      return loadingWhiteSvg;
    default:
      return loadingSvg;
  }
};

interface LoadingProps {
  colorType?: LoadingColor;
  size?: number;
  style?: React.CSSProperties;
}

const Loading = ({
  colorType = LoadingColor.primary,
  size = 50,
  style = {}
}: LoadingProps) => (
  <div
    style={Object.assign({ height: size, width: size }, style)}
  >
    <Image
      src={getImageSrc(colorType)}
      alt={'loading icon'}
      height={size}
      width={size}
    />
  </div>
);

export default Loading;
