import { ColoursList } from '../models/MediaManager';
import { VideoTemplateColours } from '../types/MediaManager';

/* We did not import into tailwind.config.js as js is not compatible with ts */
const colours = {
  blue: {
    50: '#EDF2FF',
    100: '#DEE9FF',
    200: '#C4D5FF',
    300: '#A0B9FF',
    400: '#7A91FF',
    500: '#5A6BFA',
    600: '#3C3FEF',
    700: '#2F2FD3',
    800: '#292BAA',
    900: '#292D86'
  },
  grey: {
    50: '#f9fafb',
    100: '#f2f4f7',
    200: '#eaecf0',
    300: '#d0d5dd',
    400: '#98a2b3',
    500: '#667085',
    600: '#475267',
    700: '#344054',
    800: '#1d2939',
    900: '#101828'
  },
  white: {
    400: '#ffffff'
  },
  red: {
    500: '#ef4444'
  },
  amber: {
    50: '#FFFAEB',
    100: '#fff2bd',
    200: '#FDE68A',
    300: '#FEF0C7',
    400: '#FDB022',
    900: '#99690d'
  }
};

/**
 * Attempts to get the color name from the VideoTemplateColours enum.
 * For example if the value is VideoTemplateColours.GRADIENT_GREY will return its corresponding linear-gradient.
 * If the value is not found, it will return the value itself.
 * @param colorName VideoTemplateColours | string
 * @return string
 */
const getHexCodeFromColourName = (
  colorName: VideoTemplateColours | string
): string => {
  let hexCode = null;
  ColoursList.map((colour) => {
    if (colour.name === colorName) hexCode = colour.hexCode;
  });
  if (hexCode) return hexCode;
  else return colorName;
};

const tailwindToColorCode = (colour: string) => {
  colour = colour.trim();
  if (colour.startsWith('text-')) colour = colour.replace('text-', '');
  if (colour.startsWith('bg-')) colour = colour.replace('bg-', '');

  const baseColour = colour.split('-')[0];
  const variation = colour.split('-')[1];
  if (!baseColour) return colour;
  if (!Object.keys(colours).includes(baseColour)) return colour;
  if (!variation)
    return colours[baseColour] ? colours[baseColour][400] : baseColour;
  return colours[baseColour][variation] || colour;
};
export { tailwindToColorCode, colours, getHexCodeFromColourName };
