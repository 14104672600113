import {
  FONT_FAMILY,
  VideoTemplateFonts,
  normalFontSize
} from '../utils/constant';
import { SubtitleItem } from '../components/Subtitles';
import { WordByWordSequence } from '../components/WordByWordSequence';
import React from 'react';

export const WordByWordOption: React.FC<{
  color: string;
  subtitlesContent: SubtitleItem[];
  font: VideoTemplateFonts;
}> = ({ color, subtitlesContent, font }) => (
  <div style={{ ...normalFontSize }}>
    <WordByWordSequence
      subtitlesContent={subtitlesContent}
      style={{ color, fontFamily: FONT_FAMILY(font) }}
    />
  </div>
);
