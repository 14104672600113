import {
  FONT_FAMILY,
  VideoTemplateFonts,
  normalFontSize
} from '../utils/constant';
import { Sequence } from 'remotion';
import { SubtitleItem } from '../components/Subtitles';
import { Title } from './Title';
import { WordByWordSequence } from '../components/WordByWordSequence';
import React from 'react';

export const WordByWordOption: React.FC<{
  title: string;
  color: string;
  subtitlesContent: SubtitleItem[];
  font: VideoTemplateFonts;
}> = ({ title, color, subtitlesContent, font }) => (
  <div style={{ maxHeight: 200 }}>
    <Sequence layout="none" from={0} durationInFrames={1}>
      <Title
        text={title}
        color={color}
        style={{ fontFamily: FONT_FAMILY(font) }}
      />
    </Sequence>
    <div
      style={{
        ...normalFontSize,
        width: '100%'
      }}
    >
      <WordByWordSequence
        subtitlesContent={subtitlesContent}
        style={{ color, fontFamily: FONT_FAMILY(font), textAlign: 'center' }}
      />
    </div>
  </div>
);
