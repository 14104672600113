import { IS_DEV_ENV } from '../../models/constant';
import Modal from '../Modals/BaseModal';

export const WaitlistModal = ({
  isModalOpen,
  updateModalOpen
}: {
  isModalOpen: boolean;
  updateModalOpen: (value: boolean) => void;
}) => {
  return (
    <Modal
      isVisible={isModalOpen}
      closeModal={() => updateModalOpen(false)}
      showCrossButton={false}
      width={600}
    >
      <div className="h-full max-h-[400px] overflow-hidden rounded-[10px]">
        <iframe
          className="h-full min-h-[400px] w-full"
          id="waitlist_iframe"
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          width="600px"
          src={`https://getwaitlist.com/waitlist/${IS_DEV_ENV ? 8649 : 8355}`}
        ></iframe>
        <script
          dangerouslySetInnerHTML={{
            __html: `
              document.getElementById("waitlist_iframe").src += '?ref_id=${
                IS_DEV_ENV ? 8649 : 8355
              }';
          `
          }}
        ></script>
      </div>
    </Modal>
  );
};
