import { Img } from 'remotion';
import { fontFamilyLight } from '../utils/constant';
import React, { useEffect, useState } from 'react';

export const Title: React.FC<{
  text: string;
  bannerUrl: string;
  color: string;
  name: string;
  style: any;
}> = ({ text, bannerUrl, color, style }) => {
  const [banner, setBanner] = useState(
    bannerUrl ||
      'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
  );

  useEffect(() => {
    if (bannerUrl) setBanner(bannerUrl);
  }, [bannerUrl]);

  return (
    <div
      style={{
        display: 'flex'
      }}
    >
      <Img
        style={{
          borderRadius: 10,
          width: 160,
          height: 160,
          objectFit: 'cover'
        }}
        src={
          banner ||
          'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
        }
        onError={() =>
          setBanner(
            'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
          )
        }
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          marginLeft: 48
        }}
      >
        <div
          style={Object.assign(
            {
              fontFamily: fontFamilyLight,
              fontSize: 45,
              color,
              lineHeight: '1.4', // this is custom based on the Template Design
              maxWidth: 800
            },
            style
          )}
        >
          {text}
        </div>
        {/*<div style={{ fontSize: 24, color }}>*/}
        {/*  {name}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};
