import { StandardIconProps } from '../../../components/Shared/Icon';

const lightning = (props: StandardIconProps) => {
  return (
    <svg
      width={(props.size || '24') + 'px'}
      height={(props.size || '24') + 'px'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
        stroke={props.fill || '#FDB022'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default lightning;
