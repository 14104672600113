import { BigTitleOption } from './BigTitleOption';
import {
  DEFAULT_DIMENSIONS,
  horizontalSpaceMultiplier
} from '../utils/dimension';
import { FONT_FAMILY, FONT_FAMILY_LIGHT } from '../utils/constant';
import { Footer } from '../components/Footer';
import { GenerateVideoParams } from '../Video';
import { SubtitleItem } from '../components/Subtitles';
import { SubtitleOptions } from '../../types/MediaManager';
import { TextOutput } from '../components/TextSequence';
import { Title } from './Title';
import { WordByWordOption } from './WordByWordOption';
import {
  createSpotifySubtitleArray,
  useCalculateOpacity
} from '../utils/general';
import { getHexCodeFromColourName } from '../../utils/colour';
import AudioContainer from '../components/AudioContainer';
import React, { FunctionComponent } from 'react';

export const PodcastRender: FunctionComponent<any> = (
  props: GenerateVideoParams
) => {
  const {
    title,
    audioUrl,
    bannerUrl,
    displayName,
    // avatarUrl,
    subtitles,
    subtitlesContent,
    handle,
    color,
    textColor,
    dimensionType,
    hidePromotion,
    font
  } = props;
  // avatarUrl
  const hexCode = getHexCodeFromColourName(color);
  // const isDefault = hexCode === Colours.White;

  const hexCodeTextColor = getHexCodeFromColourName(textColor);

  let collatedSubs: TextOutput[][] = [];
  if (subtitles === SubtitleOptions.BIG_TITLE)
    collatedSubs = createSpotifySubtitleArray(subtitlesContent as TextOutput[]);

  const getSelectedSubtitleConfiguration = () => {
    switch (subtitles) {
      // options are disabled on front-end landing via the SubtitleOptions Menu Component
      case SubtitleOptions.CC:
      case SubtitleOptions.NONE:
        return null;
      case SubtitleOptions.BIG_TITLE:
        return (
          <BigTitleOption
            collatedSubs={collatedSubs}
            color={hexCodeTextColor}
            font={font}
          />
        );
      case SubtitleOptions.WORD_BY_WORD:
        return (
          <WordByWordOption
            subtitlesContent={subtitlesContent as SubtitleItem[]}
            color={hexCodeTextColor}
            font={font}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div
      style={{
        background: hexCode,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          fontFamily: FONT_FAMILY(font),
          opacity: useCalculateOpacity(),
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          color: hexCodeTextColor,
          height: DEFAULT_DIMENSIONS.H,
          width: '100%',
          position: 'relative',
          paddingTop: 130,
          paddingBottom: 130
        }}
      >
        <div
          style={{
            height: DEFAULT_DIMENSIONS.H,
            paddingLeft: 48 * horizontalSpaceMultiplier(dimensionType),
            paddingRight: 48 * horizontalSpaceMultiplier(dimensionType)
          }}
        >
          <AudioContainer from={0} audioUrl={audioUrl} />
          <Title
            text={title}
            bannerUrl={bannerUrl}
            color={hexCodeTextColor}
            name={displayName}
            style={{ fontFamily: FONT_FAMILY_LIGHT(font) }}
          />
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              marginTop: 120
            }}
          >
            <div
              style={{
                height: 400,
                fontSize: 64,
                width: '100%',
                maxWidth: 1152,
                display: 'flex',
                alignItems: 'flex-start'
              }}
            >
              {getSelectedSubtitleConfiguration()}
            </div>
          </div>
        </div>
      </div>
      <Footer
        handle={handle}
        color={hexCodeTextColor}
        style={{
          alignSelf: 'flex-end'
        }}
        hidePromotion={hidePromotion}
        horizontalPadding={48 * horizontalSpaceMultiplier(dimensionType)}
      />
    </div>
  );
};
