import { BigTitleOption } from './BigTitleOption';
import { Colours } from '../../components/Colours';
import {
  DEFAULT_DIMENSIONS,
  getDimensionByName,
  horizontalSpaceMultiplier
} from '../utils/dimension';
import { DefaultOption } from './DefaultOption';
import { FONT_FAMILY } from '../utils/constant';
import { Footer } from '../components/Footer';
import { GenerateVideoParams } from '../Video';
import { Img, Sequence } from 'remotion';
import { NewAudioVisualization } from '../components/NewAudioVisualization';
import { SubtitleItem } from '../components/Subtitles';
import { SubtitleOptions } from '../../types/MediaManager';
import { TextOutput } from '../components/TextSequence';
import { WordByWordOption } from './WordByWordOption';
import {
  createSpotifySubtitleArray,
  useCalculateOpacity
} from '../utils/general';
import { getHexCodeFromColourName } from '../../utils/colour';
import AudioContainer from '../components/AudioContainer';
import CurvyBackground from './CurvyBackground';
import React, { FunctionComponent, useEffect, useState } from 'react';

export const CurvyLineRender: FunctionComponent<any> = (
  props: GenerateVideoParams
) => {
  const {
    title,
    audioUrl,
    bannerUrl,
    subtitles,
    subtitlesContent,
    handle,
    color,
    textColor,
    dimensionType,
    hidePromotion,
    font
  } = props;

  const hexCode = getHexCodeFromColourName(color);
  const isDefault = hexCode === Colours.White;

  const hexCodeTextColor = getHexCodeFromColourName(textColor);

  let collatedSubs: TextOutput[][] = [];
  if (subtitles === SubtitleOptions.BIG_TITLE)
    collatedSubs = createSpotifySubtitleArray(subtitlesContent as TextOutput[]);

  const [banner, setBanner] = useState(
    bannerUrl ||
      'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
  );

  useEffect(() => {
    if (bannerUrl) setBanner(bannerUrl);
  }, [bannerUrl]);

  const getSelectedSubtitleConfiguration = () => {
    switch (subtitles) {
      case SubtitleOptions.CC:
      case SubtitleOptions.NONE:
        return (
          <DefaultOption
            subtitlesContent={subtitlesContent as TextOutput[]}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
      case SubtitleOptions.BIG_TITLE:
        return (
          <BigTitleOption
            collatedSubs={collatedSubs}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
      case SubtitleOptions.WORD_BY_WORD:
        return (
          <WordByWordOption
            title={title}
            font={font}
            color={hexCodeTextColor}
            subtitlesContent={subtitlesContent as SubtitleItem[]}
          />
        );
      default:
        return (
          <DefaultOption
            subtitlesContent={subtitlesContent as TextOutput[]}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
    }
  };

  const templateHeight = getDimensionByName(dimensionType).H;
  const bannerHeight =
    (templateHeight - DEFAULT_DIMENSIONS.H) / 2 + DEFAULT_DIMENSIONS.H * 0.62;

  const marginTopForContent =
    dimensionType === 'portrait'
      ? bannerHeight - DEFAULT_DIMENSIONS.H * 0.2
      : bannerHeight - DEFAULT_DIMENSIONS.H * 0.2;

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          fontFamily: FONT_FAMILY(font)
        }}
      >
        <AudioContainer from={0} audioUrl={audioUrl} />
        <Sequence from={0} layout="none">
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: bannerHeight,
              position: 'absolute'
            }}
          >
            <Img
              alt="header banner"
              style={{
                objectFit: 'cover',
                height: 'auto',
                width: '100%'
              }}
              onError={() =>
                setBanner(
                  'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
                )
              }
              src={
                banner ||
                'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
              }
            />
          </div>
        </Sequence>
        <Sequence from={0} layout="none">
          <CurvyBackground
            style={{
              width: '100%',
              marginTop: marginTopForContent
            }}
            dimensionType={dimensionType}
            borderColor={textColor}
            backgroundColor={color}
          >
            <div
              style={{
                zIndex: 2,
                marginTop:
                  marginTopForContent + (dimensionType === 'portrait' ? 0 : 40),
                height: '100%',
                opacity: useCalculateOpacity()
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                  paddingLeft: 48 * horizontalSpaceMultiplier(dimensionType),
                  paddingRight: 48 * horizontalSpaceMultiplier(dimensionType)
                }}
              >
                <NewAudioVisualization
                  audioUrl={audioUrl}
                  isDefault={isDefault}
                  waveColor={hexCodeTextColor}
                  style={{ height: 180 }}
                />
                <div
                  style={{
                    height: 340,
                    display: 'flex',
                    alignItems: 'flex-start',
                    maxWidth: 1152
                  }}
                >
                  {getSelectedSubtitleConfiguration()}
                </div>
              </div>
              <Footer
                horizontalPadding={
                  48 * horizontalSpaceMultiplier(dimensionType)
                }
                handle={handle}
                hidePromotion={hidePromotion}
                color={hexCodeTextColor}
              />
            </div>
          </CurvyBackground>
        </Sequence>
      </div>
    </div>
  );
};
