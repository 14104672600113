import { AudioVisualization } from '../components/AudioVisualization';
import { Colours } from '../../components/Colours';
import { Img } from 'remotion';
import { imagerFormatter } from '../utils/imageFormatter';
import React, { useEffect, useState } from 'react';

const avatarDimensions = 190;

export const AudioPill: React.FC<{
  audioUrl: string;
  avatarUrl: string;
  isDefault: boolean;
}> = ({ audioUrl, avatarUrl, isDefault }) => {
  const [avatar, setAvatar] = useState(
    avatarUrl ||
      'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
  );

  const [playIcon, setPlayIcon] = useState('');

  useEffect(() => {
    isDefault
      ? setPlayIcon(
          'https://jpt-ugc.s3.ap-southeast-1.amazonaws.com/playIcon.svg'
        )
      : setPlayIcon(
          'https://jpt-ugc.s3.ap-southeast-1.amazonaws.com/playIconDarkGrey.svg'
        );
  }, [isDefault]);
  useEffect(() => {
    if (avatarUrl) setAvatar(avatarUrl);
  }, [avatarUrl]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: 500,
        padding: 8,
        paddingLeft: 80,
        paddingRight: 16,
        backgroundColor: isDefault ? Colours.BrightYellow : Colours.White
      }}
    >
      <Img
        src={
          playIcon ||
          'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
        }
        onError={(event) =>
          setPlayIcon(
            'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
          )
        }
      />
      <AudioVisualization
        audioUrl={audioUrl}
        isDefault={null}
        waveColor={isDefault ? Colours.White : Colours.DarkGrey}
        style={{ height: 200 }}
      />
      <Img
        alt="avatar icon"
        style={{
          objectFit: 'cover',
          width: avatarDimensions,
          height: avatarDimensions,
          borderRadius: avatarDimensions / 2,
          backgroundColor: 'white'
        }}
        src={
          avatar ||
          'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
        }
        onError={(event) =>
          setAvatar(
            'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
          )
        }
      />
    </div>
  );
};
