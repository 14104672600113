import { EXTRA_SPACE } from './WordByWordSequence';
import { FPS } from '../Video';
import { VideoConfig, useCurrentFrame, useVideoConfig } from 'remotion';
import { normalFontSize } from '../utils/constant';
import React, { useEffect, useMemo, useRef, useState } from 'react';

export type SubtitleItem = {
  f: number;
  t: number;
  c: string;
  start?: number;
  end?: number;
  text?: string;
};

const useWindowedFrameSubs = (
  src: SubtitleItem[],
  options: { windowStart?: number; windowEnd?: number } = {}
) => {
  const { windowStart = -Infinity, windowEnd = Infinity } = options;
  return useMemo(() => {
    const subsWithFrameNumbers = src.reduce<SubtitleItem[]>((acc, item) => {
      const from = item.f !== undefined ? item.f : item.start;
      const to = item.t !== undefined ? item.t : item.end;
      if (from < windowStart || to > windowEnd) return acc;
      return [
        ...acc,
        {
          ...item,
          f: from,
          t: to
        }
      ];
    }, []);
    return subsWithFrameNumbers;
  }, [FPS, src, windowEnd, windowStart]);
};

type PaginationLine = {
  index: number;
  offsetTop: number;
};
export const PaginatedSubtitles: React.FC<{
  src: SubtitleItem[];
  renderSubtitleItem?: (
    item: SubtitleItem,
    frame: number,
    config: VideoConfig,
    index?: number
  ) => React.ReactNode;
  startFrame?: number;
  endFrame?: number;
  linesPerPage: number;
}> = ({
  startFrame,
  endFrame,
  src,
  renderSubtitleItem = (item) => (
    <div style={{ ...normalFontSize, marginRight: EXTRA_SPACE }}>
      {item.c !== undefined ? item.c : item.text}
    </div>
  ),
  linesPerPage
}) => {
  const pageRef = useRef<HTMLDivElement | null>(null);
  const frame = useCurrentFrame();
  const config = useVideoConfig();
  const subtitles = useWindowedFrameSubs(src, {
    windowStart: startFrame,
    windowEnd: endFrame
  });
  const [lines, setLines] = useState<PaginationLine[]>([]);
  // let lines: PaginationLine[] = [];

  const currentSubtitleItem = subtitles
    .slice()
    .reverse()
    .find((item) => {
      const from = item.f !== undefined ? item.f : item.start;
      return from < frame;
    });

  useEffect(() => {
    const pageElement = pageRef.current;
    if (!pageElement) return;
    const lineOffsets = Array.from(pageElement.childNodes).reduce<
      PaginationLine[]
    >((acc, item) => {
      const { offsetTop, id } = item as HTMLElement;
      const lastOffsetTop = acc[acc.length - 1]?.offsetTop;
      if (lastOffsetTop === offsetTop) return acc;
      return [...acc, { index: Number(id), offsetTop }];
    }, []);
    setLines(lineOffsets);
    // lines = lineOffsets;
  }, [pageRef.current?.childNodes.length, currentSubtitleItem]);

  const lineSubs = (() => {
    const finalLines: SubtitleItem[][] = [];
    let lineIndex = 0;

    for (let i = 0; i < subtitles.length; i++) {
      const subtitleItem = subtitles[i];
      const from =
        subtitleItem.f !== undefined ? subtitleItem.f : subtitleItem.start;
      // if (from >= frame) continue;
      for (let j = 0; j < lines.length; j++) {
        const lineItem = lines[j];
        if (i >= lineItem.index) lineIndex = j;
      }
      finalLines[lineIndex] = [...(finalLines[lineIndex] ?? []), subtitleItem];
    }
    return finalLines;
  })();

  const currentLineIndex =
    currentSubtitleItem && lineSubs.length
      ? Math.max(
          0,
          lineSubs.findIndex((l) => {
            return l && l.includes(currentSubtitleItem!);
          })
        )
      : 0;

  const startLine = Math.max(0, currentLineIndex - (linesPerPage - 1));
  return (
    <>
      {/* The below code is used for real time display of the words   */}
      {lineSubs
        .slice(startLine, startLine + linesPerPage)
        .reduce((subs, item) => [...subs, ...item], [])
        .map((item, index) => renderSubtitleItem(item, frame, config, index))}
      {/*
       * The below code is used as pre-calculation of the display
       * It is used to calculate the default space and width of each word
       * */}
      <div
        ref={pageRef}
        style={{
          display: 'inline-block',
          visibility: 'hidden'
        }}
      >
        {subtitles.map((item, index) =>
          renderSubtitleItem(item, frame, config, index)
        )}
      </div>
    </>
  );
};
