import { Colours } from '../../components/Colours';
import {
  FONT_FAMILY,
  VideoTemplateFonts,
  smallerFontSize
} from '../utils/constant';
import { Sequence } from 'remotion';
import { SubtitleItem } from '../components/Subtitles';
import { Title } from './Title';
import { WordByWordSequence } from '../components/WordByWordSequence';
import React from 'react';

export const WordByWordOption: React.FC<{
  title: string;
  color: string;
  subtitlesContent: SubtitleItem[];
  isDefaultTextColor: boolean;
  font: VideoTemplateFonts;
}> = ({ title, color, isDefaultTextColor, subtitlesContent, font }) => (
  <div style={{ minHeight: '30%' }}>
    <Sequence layout="none" from={0} durationInFrames={1}>
      <Title
        text={title}
        color={color}
        isDefaultTextColor={isDefaultTextColor}
        style={{ fontFamily: FONT_FAMILY(font) }}
      />
    </Sequence>
    <div
      style={{
        ...smallerFontSize,
        textAlign: 'center',
        paddingLeft: 48,
        paddingRight: 48
      }}
    >
      <WordByWordSequence
        subtitlesContent={subtitlesContent}
        style={{
          color: isDefaultTextColor ? Colours.Black : color,
          fontFamily: FONT_FAMILY(font)
        }}
      />
    </div>
  </div>
);
