import { colours } from '../utils/colour';

const Colours = Object.assign(
  {
    Black: '#000000',
    Transparent: 'transparent',
    MainBlue: '#292D8D',
    Blue: '#3C3FEF',
    OffWhite: '#F0F0F0',
    NewWhite: '#F9F9F9',
    White: '#FFFFFF',
    Peach: '#F6A98F',
    AccentCoral: '#EE6061',
    AlertRed: '#FF0E0E',
    ExtraLightGrey: '#EAECF0',
    LightGrey: '#A9A9A9',
    MediumGrey: '#555555',
    DarkGrey: '#333333',
    BrightYellow: '#FCBF49',
    SubtitleBackground: 'rgba(0,0,0,0.4)',
    SelectedYellow: '#fee5ba',
    SelectedBlue: '#D6E6FD',
    MediumBlue: '#667085',
    ProOrange: '#FF9900',
    LightProOrange: 'rgba(252, 191, 73, 0.25)'
  },
  colours
);

const Gradient = {
  Blue: 'linear-gradient(90deg, #3D42C5 0%, #292D8D 100%)',
  GreenBlack:
    'linear-gradient(154.41deg, #00B09E 3.12%, #134D5D 70.59%, #10171F 116.17%)',
  Orange:
    'linear-gradient(147.91deg, #FFF29E -17.86%, #FF8F34 45.91%, #FF5F14 112.4%)',
  Red: 'linear-gradient(147.91deg, #FC594F -17.86%, #FF3D00 45.91%, #FF0000 112.4%)',
  White: 'linear-gradient(98.41deg, #FCFCFC 2.53%, #FBFBFB 101.65%)',
  BlueGreen:
    'linear-gradient(131deg, #2563EB 0%, #157DA8 42.86%, #059669 85.15%)',
  YellowPurple:
    'linear-gradient(135deg, #FFC415 2.40%, #FF7854 11.98%, #FF7854 22.92%, #FF5A6C 40.10%, #E2477F 57.81%, #B8408C 70.31%, #883F8F 85.74%, #533D87 100%)',
  BluePurple:
    'linear-gradient(134deg, #1D4ED8 0%, #4B44E2 35.58%, #7C3AED 100%)'
};

const Shadows = {
  Black: '0px 10px 40px rgba(108, 108, 108, 0.15)'
};

export { Colours, Gradient, Shadows };
