import { StandardIconProps } from '../../../components/Shared/Icon';

const loading = (props: StandardIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ background: 'none' }}
      width={(props.size || '25') + 'px'}
      height={(props.size || '25') + 'px'}
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <circle
        cx="50"
        cy="50"
        r="45"
        fill="none"
        stroke={props.fill || '#292d8d'}
        strokeDasharray="164.93361431346415 56.97787143782138"
        strokeWidth="12"
      >
        <animateTransform
          attributeName="transform"
          dur="1s"
          keyTimes="0;1"
          repeatCount="indefinite"
          type="rotate"
          values="0 50 50;360 50 50"
        ></animateTransform>
      </circle>
    </svg>
  );
};
export default loading;
