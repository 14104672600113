import React, { createContext, useContext, useState } from 'react';

// Type for the context state
interface SlideShowContextType {
  isMainVideoLoaded: boolean;
  setMainVideoLoaded: () => void;
}

// Create the context with default values
const SlideShowContext = createContext<SlideShowContextType | undefined>(
  undefined
);

export const SlideShowProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [isMainVideoLoaded, setIsMainVideoLoaded] = useState(false);

  const setMainVideoLoaded = () => {
    setIsMainVideoLoaded(true);
  };

  return (
    <SlideShowContext.Provider
      value={{ isMainVideoLoaded, setMainVideoLoaded }}
    >
      {children}
    </SlideShowContext.Provider>
  );
};

// Custom hook to use the SlideShow context
export const useSlideShow = (): SlideShowContextType => {
  const context = useContext(SlideShowContext);
  if (!context) {
    throw new Error('useSlideShow must be used within a SlideShowProvider');
  }
  return context;
};
