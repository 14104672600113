import { StandardIconProps } from '../../../components/Shared/Icon';

const gif = (props: StandardIconProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2222 3.33333C14.4667 3.33333 14.6667 3.53333 14.6667 3.77778V12.6667C14.6667 12.9111 14.4667 13.1111 14.2222 13.1111H1.77778C1.53333 13.1111 1.33333 12.9111 1.33333 12.6667V3.77778C1.33333 3.53333 1.53333 3.33333 1.77778 3.33333H14.2222ZM1.77778 2C0.797222 2 0 2.79722 0 3.77778V12.6667C0 13.6472 0.797222 14.4444 1.77778 14.4444H14.2222C15.2028 14.4444 16 13.6472 16 12.6667V3.77778C16 2.79722 15.2028 2 14.2222 2H1.77778ZM8.22222 5.55556C7.85278 5.55556 7.55556 5.85278 7.55556 6.22222V10.2222C7.55556 10.5917 7.85278 10.8889 8.22222 10.8889C8.59167 10.8889 8.88889 10.5917 8.88889 10.2222V6.22222C8.88889 5.85278 8.59167 5.55556 8.22222 5.55556ZM9.77778 6.22222V8.44444V10.2222C9.77778 10.5917 10.075 10.8889 10.4444 10.8889C10.8139 10.8889 11.1111 10.5917 11.1111 10.2222V9.11111H12.2222C12.5917 9.11111 12.8889 8.81389 12.8889 8.44444C12.8889 8.075 12.5917 7.77778 12.2222 7.77778H11.1111V6.88889H12.8889C13.2583 6.88889 13.5556 6.59167 13.5556 6.22222C13.5556 5.85278 13.2583 5.55556 12.8889 5.55556H10.4444C10.075 5.55556 9.77778 5.85278 9.77778 6.22222ZM3.55556 8.22222C3.55556 7.48611 4.15278 6.88889 4.88889 6.88889C5.11111 6.88889 5.31667 6.94167 5.5 7.03611C5.82778 7.20556 6.23056 7.07778 6.39722 6.75C6.56389 6.42222 6.43889 6.01944 6.11111 5.85278C5.74444 5.66389 5.32778 5.55556 4.88889 5.55556C3.41667 5.55556 2.22222 6.75 2.22222 8.22222C2.22222 9.69444 3.41667 10.8889 4.88889 10.8889C5.43333 10.8889 5.93056 10.7194 6.35556 10.45C6.55 10.3278 6.66667 10.1139 6.66667 9.88611V8.44444C6.66667 8.075 6.36944 7.77778 6 7.77778H5.11111C4.74167 7.77778 4.44444 8.075 4.44444 8.44444C4.44444 8.81389 4.74167 9.11111 5.11111 9.11111H5.33333V9.475C5.18611 9.52778 5.03889 9.55556 4.88889 9.55556C4.15278 9.55556 3.55556 8.95833 3.55556 8.22222Z"
        fill={props.fill || '#344054'}
      />
    </svg>
  );
};

export default gif;
