import { Colours } from '../../components/Colours';
import { FPS, NUMBER_OF_SAMPLES } from '../Video';
import { cacheS3Url } from '../../utils/s3';
import { useAudioData, visualizeAudio } from '@remotion/media-utils';
import { useCurrentFrame } from 'remotion';
import React from 'react';

export const AudioVisualization: React.FC<{
  audioUrl: string;
  isDefault: boolean;
  waveColor?: string; // hexCode specific to DefaultRender
  style?: React.CSSProperties;
  maxHeight?: number;
}> = ({ audioUrl, isDefault, waveColor, style, maxHeight }) => {
  const frame = useCurrentFrame();
  const audioData = useAudioData(cacheS3Url(audioUrl));

  if (!audioData) {
    return null;
  }

  const visualization = visualizeAudio({
    fps: FPS,
    frame,
    audioData,
    numberOfSamples: NUMBER_OF_SAMPLES,
    optimizeFor: 'speed'
  });

  return (
    <div
      style={Object.assign(
        {
          width: 10,
          transform: 'rotate(-90deg)', // the width, height, and margin below are reversed, top and bottom are left and right respectively.
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexFlow: 'column'
        },
        style
      )}
    >
      {visualization.slice(0, NUMBER_OF_SAMPLES).map((v, index) => {
        const enlarger = Math.max(1, index * 0.05 * 80);
        return (
          <div
            key={index}
            style={{
              minWidth: 30,
              maxWidth: 150 || maxHeight,
              width: 30 + 100 * v * enlarger,
              maxHeight: 2,
              paddingTop: 5,
              paddingBottom: 5,
              marginTop: 5,
              marginBottom: 5,
              borderRadius: 50,
              backgroundColor: waveColor
                ? waveColor
                : isDefault
                ? Colours.BrightYellow
                : Colours.White
            }}
          />
        );
      })}
    </div>
  );
};
