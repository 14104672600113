import { StandardIconProps } from '../../../components/Shared/Icon';

const dropdown_open = (props: StandardIconProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 20H29M11 14H29M11 26H29"
        stroke={props.fill || '#344054'}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default dropdown_open;
