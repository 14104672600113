import React, { useEffect, useState } from 'react';
import SourceModal from 'react-modal';
import styled, { css } from 'styled-components';

import { Colours } from '../Colours';
import { useModal } from '../../hooks/useModal';
import Icon, { IconType } from '../Shared/Icon';
const defaultCustomStyles = {
  content: {
    margin: 'auto',
    marginTop: '50vh',
    transform: 'translateX(-50%) translateY(-50%)',
    background: 'white',
    boxShadow: '0px 2px 30px rgba(174, 174, 174, 0.2)',
    borderRadius: '10px',
    outline: 'none',
    border: 'none',
    backgroundColor: 'white',
    padding: 0,
    inset: 0,
    marginLeft: '50vw',
    position: 'relative'
  },
  overlay: {
    overflow: 'hidden',
    backgroundColor: 'rgba(71, 71, 71, 0.3)',
    zIndex: 1000
  }
};

const RawModal = styled(SourceModal)`
  width: ${(props) => props.width}px;
  @media (max-width: 800px) {
    width: calc(100% - 24px);
  }
`;

const Modal = (props: any) => {
  const {
    children,
    style,
    width,
    isVisible: forceIsVisible,
    showCrossButton,
    closeModal: forceCloseModal,
    shouldCloseOnOverlayClick,
    transparentBackdrop,
    className
  } = props;
  const { isVisible, closeModal } = useModal();
  const [customStyles, setCustomStyles] = useState(defaultCustomStyles);

  useEffect(() => {
    const newStyles = customStyles;
    if (transparentBackdrop) {
      newStyles.overlay = Object.assign(newStyles.overlay, {
        backgroundColor: 'transparent'
      });
      newStyles.content = Object.assign(newStyles.content, {
        boxShadow:
          '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)'
      });
    }
    newStyles.content = Object.assign(newStyles.content, style);
    setCustomStyles(newStyles);
  }, [style, transparentBackdrop]);

  return (
    <RawModal
      isOpen={forceIsVisible || isVisible}
      style={customStyles}
      contentLabel="Example Modal"
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onRequestClose={
        forceCloseModal ? () => forceCloseModal() : () => closeModal()
      }
      width={width}
      className={className}
    >
      {showCrossButton ? (
        <div
          style={{
            cursor: 'pointer',
            position: 'absolute',
            left: 16,
            top: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 32,
            height: 32
          }}
          onClick={() => closeModal()}
        >
          <Icon size={16} type={IconType.Close} color={Colours.MediumGrey} />
        </div>
      ) : null}
      {children}
    </RawModal>
  );
};

export default Modal;
