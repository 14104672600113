import { Colours } from '../Colours';
import { editIcon } from '../../assets/generalAssets';
import Image from 'next/image';
import React from 'react';

interface ImageUploadProps {
  size?: number;
}

const ImageUpload = ({ size = 50 }: ImageUploadProps): JSX.Element => (
  <label
    htmlFor="upload-avatar"
    style={{
      display: 'flex',
      position: 'absolute',
      bottom: 0,
      right: 0,
      backgroundColor: Colours.White,
      zIndex: 1,
      padding: 5,
      height: size / 4,
      width: size / 4,
      borderRadius: size / 4 / 2,
      cursor: 'pointer',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <Image
      src={editIcon}
      alt={'edit icon'}
      height={size / 6}
      width={size / 6}
    />
  </label>
);

export default ImageUpload;
