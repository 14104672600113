import { BigTitleSequence } from '../components/BigTitleSequence';
import { FONT_FAMILY, VideoTemplateFonts } from '../utils/constant';
import { Sequence } from 'remotion';
import { TextOutput } from '../components/TextSequence';
import { Title } from './Title';
import React from 'react';

export const BigTitleOption: React.FC<{
  collatedSubs: TextOutput[][];
  title: string;
  color: string;
  font: VideoTemplateFonts;
}> = ({ title, color, collatedSubs, font }) => (
  <div>
    <Sequence layout="none" from={0} durationInFrames={1}>
      <Title
        text={title}
        color={color}
        style={{ fontFamily: FONT_FAMILY(font) }}
      />
    </Sequence>
    <BigTitleSequence
      collatedSubs={collatedSubs}
      color={color}
      style={{ fontSize: 64, fontFamily: FONT_FAMILY(font) }}
    />
  </div>
);
