import { Easing, interpolate, useVideoConfig } from 'remotion';
import { FPS } from '../Video';
import { PODART_FONT_SIZE } from '../PodArtRender/PodArtRender';
import { PaginatedSubtitles, SubtitleItem } from './Subtitles';
import { normalFontSize, smallerFontSize } from '../utils/constant';
import React from 'react';

export const EXTRA_SPACE = 12;

export const WordByWordSequence: React.FC<{
  subtitlesContent: SubtitleItem[];
  style?: React.CSSProperties;
}> = ({ subtitlesContent, style }) => {
  const videoConfig = useVideoConfig();
  if (!subtitlesContent || subtitlesContent.length === 0) return null;

  /**
   * Accepts a time in string, parses it into a float and returns the duration in frames.
   * @param input number
   * @return number
   */
  const calculateFrames = (input: number): number =>
    Math.floor(parseFloat(String(input)) * FPS);

  const updatedSubtitlesContent = subtitlesContent.map((item) => {
    const startingSecond = item.f !== undefined ? item.f : item.start;
    const endingSecond = item.t !== undefined ? item.t : item.end;
    const startInFrames = calculateFrames(startingSecond);
    const endInFrames = calculateFrames(endingSecond);
    return {
      ...item,
      f: startInFrames,
      t: endInFrames
    };
  });
  return (
    <PaginatedSubtitles
      src={updatedSubtitlesContent}
      startFrame={0}
      endFrame={videoConfig.durationInFrames}
      linesPerPage={3}
      renderSubtitleItem={(item, frame, config, index) => {
        const from = item.f !== undefined ? item.f : item.start;
        const content = item.c !== undefined ? item.c : item.text;
        return (
          <div
            key={index}
            id={String(index)}
            style={Object.assign(
              {
                backfaceVisibility: 'hidden',
                display: 'inline-block',
                marginRight: EXTRA_SPACE,
                opacity: interpolate(frame, [from, from + 15], [0, 1], {
                  extrapolateLeft: 'clamp',
                  extrapolateRight: 'clamp'
                }),
                transform: `perspective(1000px) translateY(${interpolate(
                  frame,
                  [from, from + 15],
                  [0.25, 0],
                  {
                    easing: Easing.out(Easing.quad),
                    extrapolateLeft: 'clamp',
                    extrapolateRight: 'clamp'
                  }
                )}em)`,
                ...smallerFontSize
              },
              style
            )}
          >
            {content}
          </div>
        );
      }}
    />
  );
};
