import { BigTitleSequence } from '../components/BigTitleSequence';
import { Colours } from '../../components/Colours';
import { FONT_FAMILY, VideoTemplateFonts } from '../utils/constant';
import { Sequence } from 'remotion';
import { TextOutput } from '../components/TextSequence';
import { Title } from './Title';
import React from 'react';

export const BigTitleOption: React.FC<{
  collatedSubs: TextOutput[][];
  title: string;
  color: string;
  isDefaultTextColor: boolean;
  font: VideoTemplateFonts;
}> = ({ title, color, collatedSubs, isDefaultTextColor, font }) => (
  <>
    <Sequence layout="none" from={0} durationInFrames={1}>
      <Title
        text={title}
        color={color}
        isDefaultTextColor={isDefaultTextColor}
        style={{ fontFamily: FONT_FAMILY(font) }}
      />
    </Sequence>
    <BigTitleSequence
      collatedSubs={collatedSubs}
      color={isDefaultTextColor ? Colours.Black : color}
      style={{
        fontSize: 64,
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20,
        fontFamily: FONT_FAMILY(font)
      }}
    />
  </>
);
