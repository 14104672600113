import {
  AudioProcessingStatus,
  ColourInfo,
  MediaManagerFS,
  VideoConfig,
  VideoGenerationStatus,
  VideoTemplateColours,
  videoGenerationStatus
} from '../types/MediaManager';
import { collectionPath, firestore } from './firebase';
import React from 'react';
import _ from 'lodash';
import firestoreHelper from './FirestoreHelper';
import moment, { Moment } from 'moment';

import { videoGenerationStatus as VStatus } from '../types/MediaManager';

interface MediaManagerHelper {
  collectionPath: string;
  data: MediaManagerFS | null;
  originalData: MediaManagerFS | null;
  refreshData: (this: MediaManagerHelper) => Promise<void>;
  create: (this: MediaManagerHelper, authorId: string, audioId: string) => void;
  getDataFromFirestore: (
    this: MediaManagerHelper,
    answerId: string
  ) => Promise<MediaManagerFS | null>;
  addAudioStatus: (
    this: MediaManagerHelper,
    status: AudioProcessingStatus
  ) => void;
  addVideoGenerationStatus: (
    this: MediaManagerHelper,
    id: string,
    status: VideoGenerationStatus,
    fieldsToUnset?: (keyof VideoGenerationStatus)[]
  ) => void;
  cleanData: (this: MediaManagerHelper) => void;
  updateFirestore: (
    this: MediaManagerHelper,
    updateAudioConfig?: boolean,
    forceSave?: boolean
  ) => Promise<void>;
  createOnFirestore: (this: MediaManagerHelper) => Promise<void>;
  getVideoStatusIdFromConfig: (
    this: MediaManagerHelper,
    config: VideoConfig
  ) => string | null;
  currentGenerationInProgress: (
    this: MediaManagerHelper,
    videoId: string
  ) => Promise<boolean | null>;
  cleanupPreviousGeneration: (
    this: MediaManagerHelper,
    videoId: string
  ) => Promise<void>;
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export const updateMediaManagerAudioStatus = async (
  answerId: string,
  status: AudioProcessingStatus
) => {
  mediaManagerHelper.cleanData();
  await mediaManagerHelper.getDataFromFirestore(answerId);
  mediaManagerHelper.addAudioStatus(status);
  await mediaManagerHelper.updateFirestore(true);
};

const mediaManagerHelper: MediaManagerHelper = {
  collectionPath,
  data: null,
  originalData: null,
  create(authorId: string, audioId: string) {
    this.data = {
      id: audioId,
      author_id: authorId,
      audio_id: audioId,
      audio_processing_status: {},
      video_generation_status: {},
      created_at: new Date().toISOString(),
      last_updated: new Date().toISOString()
    };
  },
  addAudioStatus(status: AudioProcessingStatus) {
    if (this.data === null) return;
    this.data.audio_processing_status[status] = new Date().toISOString();
  },
  addVideoGenerationStatus(
    videoId: string,
    status: VideoGenerationStatus,
    fieldsToUnset?: (keyof VideoGenerationStatus)[]
  ) {
    if (this.data === null) return;
    const currentStatus = this.data.video_generation_status[videoId] || {};
    const newStatus = Object.assign(currentStatus, status);
    if (fieldsToUnset && fieldsToUnset.length)
      fieldsToUnset.map((field) => delete newStatus[field]);
    if (_.isEqual(newStatus, this.data.video_generation_status[videoId]))
      return;

    newStatus.last_updated = new Date().toISOString();
    this.data.video_generation_status[videoId] = newStatus;
  },
  async refreshData() {
    const doc = await firestore
      .collection(this.collectionPath)
      .doc(this.data.audio_id)
      .get();
    if (doc.exists) {
      const data = doc.data();
      this.data = mergeDeep(data, this.data);
    }
    return;
  },
  async updateFirestore(updateAudioConfig = false, forceSave = false) {
    try {
      if (this.data === null) return;
      if (_.isEqual(this.data, this.originalData)) return;

      this.data.last_updated = new Date().toISOString();
      if (!updateAudioConfig) delete this.data.audio_processing_status;
      await firestoreHelper.updateFirestore(
        {
          collectionPath: this.collectionPath,
          data: this.data,
          docId: this.data.audio_id
        },
        forceSave
      );
    } catch (err) {
      console.log('failed to updateFirestore- ' + JSON.stringify(this.data));
      console.error(err);
    }
  },
  async createOnFirestore() {
    if (this.data === null) return;
    this.data.last_updated = new Date().toISOString();
    await firestore
      .collection(this.collectionPath)
      .doc(this.data.audio_id)
      .set(this.data);
  },
  async getDataFromFirestore(audioId: string) {
    const doc = await firestore
      .collection(this.collectionPath)
      .doc(audioId)
      .get();
    if (doc.exists) {
      const data = doc.data();
      this.data = _.cloneDeep(data) as MediaManagerFS;
      this.originalData = _.cloneDeep(data) as MediaManagerFS;
      return data as MediaManagerFS;
    }
    return null;
  },
  cleanData() {
    this.data = null;
  },
  async cleanupPreviousGeneration(videoId: string) {
    try {
      if (!this.data) return;
      const data = this.data as MediaManagerFS;
      if (!data.video_generation_status[videoId]) return null;
      await this.addVideoGenerationStatus(
        videoId,
        {
          status: videoGenerationStatus.draft
        },
        ['metadata', 'render_id', 'url', 'error']
      );
      await this.updateFirestore();
    } catch (err) {
      console.log(
        'failed to cleanupPreviousGeneration with videoId: ',
        videoId
      );
      console.error(err);
    }
  },
  async currentGenerationInProgress(videoId: string) {
    if (!this.data) return null;
    if (!this.data.video_generation_status[videoId]) return null;
    let status = (
      this.data.video_generation_status[videoId] as VideoGenerationStatus
    ).status;
    if (!status) return false;
    if (status === videoGenerationStatus.tbd) return true;
    if (status === videoGenerationStatus.blocked_by_transcribe_pretty_completed)
      return true;
    if (
      this.data.video_generation_status[videoId].created_at >
      moment().subtract(10, 'minutes').toISOString()
    ) {
      if (status === videoGenerationStatus.in_progress) return true;
      if (status.endsWith('%')) return true;
    }
    return false;
  },
  getVideoStatusIdFromConfig(config: VideoConfig): string | null {
    if (!this.data) return null;
    if (!config) return null;
    const data = this.data as MediaManagerFS;
    let key: string | null = null;
    const sortedConfig = sortKeysAlphabetically(config);
    const videoGenerationStatusList = Object.keys(data.video_generation_status);
    videoGenerationStatusList.map((id, index) => {
      const item = data.video_generation_status[id];
      if (item === undefined || item.config === undefined) return null;
      const sortedFirestoreConfig = sortKeysAlphabetically(item.config);
      if (
        JSON.stringify(sortedFirestoreConfig) === JSON.stringify(sortedConfig)
      )
        return (key = id);
      else return null;
    });
    return key;
  }
};

export const TextColoursList: ColourInfo[] = [
  { name: VideoTemplateColours.WHITE, hexCode: '#FFFFFF' },
  { name: VideoTemplateColours.BLACK, hexCode: '#000000' }
];

/**
 * Sorts keys alphabetically. Use before JSON.stringifying the VideoConfig object.
 * ref: https://stackoverflow.com/a/9658712
 * @param obj VideoConfig
 * @return VideoConfig
 */
export const sortKeysAlphabetically = (obj: VideoConfig): VideoConfig =>
  Object.fromEntries(Object.entries(obj).sort()) as VideoConfig;

// intentionally duplicating white and honey yellow instead of importing from Colour.tsx because the color config might change.
export const ColoursList: ColourInfo[] = [
  { name: VideoTemplateColours.WHITE, hexCode: '#FFFFFF' }, // actually Colours.White
  { name: VideoTemplateColours.HONEY_YELLOW, hexCode: '#FCBF49' }, // actually Colours.BrightYellow
  { name: VideoTemplateColours.VIVID_BLUE, hexCode: '#0E82D1' },
  { name: VideoTemplateColours.CORAL_RED, hexCode: '#F45959' },
  {
    name: VideoTemplateColours.GRADIENT_GREY,
    hexCode:
      'linear-gradient(138.98deg, #485461 2%, #3A4450 43.71%, #14191E 107.35%)',
    svg: (
      <linearGradient
        id="linearGradientYo"
        x1="13.175%"
        y1="1.32613e-06%"
        x2="47.2541%"
        y2="149.841%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#485461" />
        <stop offset="0.395925" stopColor="#3A4450" />
        <stop offset="1" stopColor="#14191E" />
      </linearGradient>
    )
  },
  {
    name: VideoTemplateColours.GRADIENT_GREEN,
    hexCode:
      'linear-gradient(154.41deg, #00B09E 3.12%, #134D5D 70.59%, #10171F 116.17%)',
    svg: (
      <linearGradient
        id="linearGradientYo"
        x1="29.4525%"
        y1="-7.68825e-07%"
        x2="45.9384%"
        y2="131.685%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B09E" />
        <stop offset="0.596858" stopColor="#134D5D" />
        <stop offset="1" stopColor="#10171F" />
      </linearGradient>
    )
  },
  {
    name: VideoTemplateColours.GRADIENT_BLUE,
    hexCode: 'linear-gradient(90deg, #3D42C5 0%, #292D8D 100%)',
    svg: (
      <linearGradient
        id="linearGradientYo"
        x1="1.17719e-06%"
        y1="40%"
        x2="316%"
        y2="40%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3D42C5" />
        <stop offset="1" stopColor="#292D8D" />
      </linearGradient>
    )
  },
  {
    name: VideoTemplateColours.GRADIENT_ORANGE,
    hexCode:
      'linear-gradient(147.91deg, #FFF29E -17.86%, #FF8F34 45.91%, #FF5F14 112.4%)',
    svg: (
      <linearGradient
        id="linearGradientYo"
        x1="-65.175%"
        y1="-12.9%"
        x2="-38.9271%"
        y2="152.47%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFF29E" />
        <stop offset="0.489583" stopColor="#FF8F34" />
        <stop offset="1" stopColor="#FF5F14" />
      </linearGradient>
    )
  },
  {
    name: VideoTemplateColours.GRADIENT_PURPLE,
    hexCode:
      'linear-gradient(133.55deg, #1D4ED8 0%, #4B44E2 34.73%, #7C3AED 97.59%)',
    svg: (
      <linearGradient
        id="linearGradientYo"
        x1="0%"
        y1="0%"
        x2="40.9674%"
        y2="148.938%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1D4ED8" />
        <stop offset="0.355844" stopColor="#4B44E2" />
        <stop offset="1" stopColor="#7C3AED" />
      </linearGradient>
    )
  },
  {
    name: VideoTemplateColours.GRADIENT_BLUE_GREEN,
    hexCode:
      'linear-gradient(137.37deg, #2563EB 0%, #157DA8 42.51%, #059669 99.17%)',
    svg: (
      <linearGradient
        id="linearGradientYo"
        x1="0"
        y1="0"
        x2="34.6624%"
        y2="144.031%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2563EB" />
        <stop offset="0.428613" stopColor="#157DA8" />
        <stop offset="1" stopColor="#059669" />
      </linearGradient>
    )
  },
  {
    name: VideoTemplateColours.GRADIENT_LIGHT_PINK_YELLOW,
    hexCode:
      'linear-gradient(133.55deg, #F472B6 0%, #F79B89 56.93%, #FCD34D 97.59%)',
    svg: (
      <linearGradient
        id="linearGradientYo"
        x1="0"
        y1="0"
        x2="39.8456%"
        y2="149.593%"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F472B6" />
        <stop offset="0.583333" stopColor="#F79B89" />
        <stop offset="1" stopColor="#FCD34D" />
      </linearGradient>
    )
  },
  {
    name: VideoTemplateColours.GRADIENT_YELLOW_PINK,
    hexCode:
      'linear-gradient(135.05deg, #FFC415 2.37%, #FF7854 11.83%, #FF7854 22.63%, #FF5A6C 39.6%, #E2477F 57.09%, #B8408C 69.43%, #883F8F 84.66%, #533D87 98.75%)',
    svg: (
      <linearGradient
        id="linearGradientYo"
        x1="0"
        y1="0"
        x2="38.5652%"
        y2="147.789%"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0240228" stopColor="#FFC415" />
        <stop offset="0.119792" stopColor="#FF7854" />
        <stop offset="0.229167" stopColor="#FF7854" />
        <stop offset="0.401042" stopColor="#FF5A6C" />
        <stop offset="0.578125" stopColor="#E2477F" />
        <stop offset="0.703125" stopColor="#B8408C" />
        <stop offset="0.857363" stopColor="#883F8F" />
        <stop offset="1" stopColor="#533D87" />
      </linearGradient>
    )
  }
];

export default mediaManagerHelper;
