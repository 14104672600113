
import { DimensionType } from '../audiogram/utils/dimension';
import { VideoTemplateFonts } from '../audiogram/utils/constant';

export enum videoGenerationStatus {
  draft = 'draft', // to be determined
  tbd = 'tbd', // to be determined
  blocked_by_transcribe_pretty_completed = 'blocked_by_transcribe_pretty_completed',
  in_progress = 'in_progress',
  generated = 'generated',
  downloaded = 'downloaded',
  '10%' = '10%',
  '20%' = '20%',
  '30%' = '30%',
  '40%' = '40%',
  '50%' = '50%',
  '60%' = '60%',
  '70%' = '70%',
  '80%' = '80%',
  '90%' = '90%'
}



export type DateString = string;

export enum AudioProcessingStatus {
  raw_uploaded = 'raw_uploaded',
  transcoding_started = 'transcoding_started',
  transcoding_completed = 'transcoding_completed',
  transcribe_source_raw_started = 'transcribe_source_raw_started',
  transcribe_source_succeeded = 'transcribe_source_succeeded',
  transcribe_source_failed = 'transcribe_source_failed',
  transcribe_source_completed = 'transcribe_source_completed',
  transcribe_pretty_started = 'transcribe_pretty_started',
  transcribe_pretty_completed = 'transcribe_pretty_completed',
  transcribe_pretty_modified = 'transcribe_pretty_modified',
  transcribe_source_modified = 'transcribe_source_modified'
}

export enum VideoTemplateOptions {
  DEFAULT = 'DefaultRender',
  WITH_IMAGE = 'ImageRender',
  PRODUCT_HUNT = 'ProductHuntRender',
  FULL_SIZE = 'FullSizeRender',
  PODCAST = 'PodcastRender',
  PODART = 'PodArtRender',
  CURVY_LINE = 'CurvyLineRender',
  DIAGONAL = 'DiagonalRender'
}

export enum VideoTemplateColours {
  BLACK = 'black', // only used for textColor
  WHITE = 'white', // white is default.
  HONEY_YELLOW = 'honey_yellow',
  VIVID_BLUE = 'vivid_blue',
  CORAL_RED = 'coral_red',
  GRADIENT_GREY = 'gradient_grey',
  GRADIENT_GREEN = 'gradient_green',
  GRADIENT_BLUE = 'gradient_blue',
  GRADIENT_ORANGE = 'gradient_orange',
  GRADIENT_PURPLE = 'gradient_purple',
  GRADIENT_BLUE_GREEN = 'gradient_blue_green',
  GRADIENT_LIGHT_PINK_YELLOW = 'gradient_light_pink_yellow',
  GRADIENT_YELLOW_PINK = 'gradient_yellow_pink'
}

export interface ColourInfo {
  name: VideoTemplateColours;
  hexCode: string;
  svg?: React.ReactNode;
}

export enum SubtitleOptions {
  NONE = 'None',
  CC = 'Closed Caption',
  BIG_TITLE = 'Big Title',
  WORD_BY_WORD = 'Word by Word',
  REELS = 'Reels'
}

/**
 * Note: Please sort all options ALPHABETICALLY, since we will need to
 * JSON.stringify and compare it to the firestore config.
 * Use along with sortKeysAlphabetically() when comparing.
 */
export interface VideoConfig {
  avatar: string;
  banner: string | null; // default to null
  color: VideoTemplateColours | string;
  dimensionType: DimensionType;
  handle: string | null;
  subtitles: SubtitleOptions;
  template: VideoTemplateOptions;
  textColor: string;
  hidePromotion?: boolean;
  title: string;
  font?: VideoTemplateFonts;
}

export type VideoConfigTypes =
  | string
  | boolean
  | null
  | VideoTemplateColours
  | SubtitleOptions
  | VideoTemplateOptions
  | VideoTemplateFonts;
export type VideoConfigOptions = keyof VideoConfig;


export interface VideoGenerationStatus {
  status?: videoGenerationStatus;
  video_title?: string;
  reserved_emails?: string[];
  config?: VideoConfig;
  created_at?: DateString;
  url?: string | null;
  last_updated?: DateString;
  download_count?: number;
  render_id?: string;
  audio_id?: string
  metadata?: any;
  error?: any;
  duration_in_seconds?: number;
  is_deleted?: boolean;
  is_debian?: boolean
}

export type AudioProcessingStatusObject = {
  [key in AudioProcessingStatus]?: DateString;
};

type VideoGenerationStatusObject = {
  // id is a uuidv4 string generated on the backend, and is used to find the appropriate key.
  [id in string]?: VideoGenerationStatus;
};

export interface MediaManagerFS {
  id?: string;
  author_id: string;
  audio_id: string;
  audio_error?: string;
  audio_url?: string;
  error?: string;
  audio_processing_status: AudioProcessingStatusObject;
  video_generation_status: VideoGenerationStatusObject;
  created_at: DateString;
  last_updated: DateString;
  metadata?: any;
}