import { BigTitleOption } from './BigTitleOption';
import { Colours } from '../../components/Colours';
import {
  DEFAULT_DIMENSIONS,
  horizontalSpaceMultiplier
} from '../utils/dimension';
import { DefaultOption } from './DefaultOption';
import { FONT_FAMILY } from '../utils/constant';
import { Footer } from '../components/Footer';
import { GenerateVideoParams } from '../Video';
import { Img, Sequence } from 'remotion';
import { NewAudioVisualization } from '../components/NewAudioVisualization';
import { SubtitleItem } from '../components/Subtitles';
import { SubtitleOptions } from '../../types/MediaManager';
import { TextOutput } from '../components/TextSequence';
import { WordByWordOption } from './WordByWordOption';
import {
  createSpotifySubtitleArray,
  useCalculateOpacity
} from '../utils/general';
import { getHexCodeFromColourName } from '../../utils/colour';
import AudioContainer from '../components/AudioContainer';
import React, { FunctionComponent, useEffect, useState } from 'react';

export const PODART_FONT_SIZE = 54;
export const PodArtRender: FunctionComponent<any> = (
  props: GenerateVideoParams
) => {
  const {
    title,
    audioUrl,
    subtitles,
    subtitlesContent,
    handle,
    color,
    textColor,
    dimensionType,
    hidePromotion,
    font,
    bannerUrl
  } = props;
  const hexCode = getHexCodeFromColourName(color);
  const isDefault = hexCode === Colours.White;
  const [banner, setBanner] = useState<string>();
  const hexCodeTextColor = getHexCodeFromColourName(textColor);

  let collatedSubs: TextOutput[][] = [];
  if (subtitles === SubtitleOptions.BIG_TITLE)
    collatedSubs = createSpotifySubtitleArray(subtitlesContent as TextOutput[]);

  useEffect(() => {
    if (bannerUrl) setBanner(bannerUrl);
  }, [bannerUrl]);

  const getSelectedSubtitleConfiguration = () => {
    switch (subtitles) {
      case SubtitleOptions.CC:
      case SubtitleOptions.NONE:
        return (
          <DefaultOption
            subtitlesContent={subtitlesContent as TextOutput[]}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
      case SubtitleOptions.BIG_TITLE:
        return (
          <BigTitleOption
            collatedSubs={collatedSubs}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
      case SubtitleOptions.WORD_BY_WORD:
        return (
          <WordByWordOption
            title={title}
            color={hexCodeTextColor}
            subtitlesContent={subtitlesContent as SubtitleItem[]}
            font={font}
          />
        );
      default:
        return (
          <DefaultOption
            subtitlesContent={subtitlesContent as TextOutput[]}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
    }
  };

  return (
    <div
      style={{
        background: hexCode,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          flex: 1,
          fontFamily: FONT_FAMILY(font),
          paddingBottom: '76',
          opacity: useCalculateOpacity()
        }}
      >
        <div
          style={{
            height: DEFAULT_DIMENSIONS.H,
            paddingTop: '10%',
            paddingLeft: 48 * horizontalSpaceMultiplier(dimensionType),
            paddingRight: 48 * horizontalSpaceMultiplier(dimensionType),
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center'
          }}
        >
          <AudioContainer from={0} audioUrl={audioUrl} />
          <Img
            style={{
              width: 480,
              height: 480,
              minWidth: 480,
              minHeight: 480,
              maxWidth: 480,
              maxHeight: 480,
              objectFit: 'cover'
            }}
            src={
              banner ||
              'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
            }
            onError={() =>
              setBanner(
                'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
              )
            }
          />
          <div
            style={{
              height: '18%',
              display: 'flex',
              alignItems: 'center',
              maxWidth: 1552,
              marginTop: 40,
              marginBottom: 40
            }}
          >
            {getSelectedSubtitleConfiguration()}
          </div>
          <div style={{ height: 200, marginTop: 60 }}>
            <Sequence from={0} layout="none">
              <NewAudioVisualization
                audioUrl={audioUrl}
                isDefault={isDefault}
                waveColor={hexCodeTextColor}
                style={{ width: 200 }}
              />
            </Sequence>
          </div>
        </div>
      </div>
      <Footer
        horizontalPadding={48 * horizontalSpaceMultiplier(dimensionType)}
        handle={handle}
        hidePromotion={hidePromotion}
        color={hexCodeTextColor}
      />
    </div>
  );
};
