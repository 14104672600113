import {
  Composition,
  continueRender,
  delayRender,
  getInputProps,
  getRemotionEnvironment
} from 'remotion';
import { CurvyLineRender } from './CurvyLineRender/CurvyLineRender';
import { DEFAULT_DIMENSIONS, Dimension } from './utils/dimension';
import { DefaultRender } from './DefaultRender/DefaultRender';
import { DiagonalRender } from './DiagonalRender/DiagonalRender';
import { DimensionType, getDimensionByName } from './utils/dimension';
import { FullSizeRender } from './FullSizeRender/FullSizeRender';
import { ImageRender } from './ImageRender/ImageRender';
import { JUPITRR_HANDLE, VideoTemplateFonts } from './utils/constant';
import { PodArtRender } from './PodArtRender/PodArtRender';
import { PodcastRender } from './PodcastRender/PodcastRender';
import { ProductHuntRender } from './ProductHuntRender/ProductHuntRender';
import {
  SubtitleOptions,
  VideoTemplateColours,
  VideoTemplateOptions
} from '../types/MediaManager';
import { bigTitle } from './components/test';
import { getAudioDurationInSeconds } from '@remotion/media-utils';
import { useDefaultFont } from './utils/use-fonts';
import React, { useEffect, useState } from 'react';

export const FPS = 30;
export const MAX_NUMBER_OF_LINES = 2;
export const NUMBER_OF_SAMPLES = 16; // 256
// Config.Rendering.setImageFormat('jpeg');
// Config.Rendering.setQuality(100);

export interface GenerateVideoParams {
  audioUrl: string;
  avatarUrl: string;
  bannerUrl: string;
  color: VideoTemplateColours | string;
  handle: string;
  displayName: string;
  subtitles: SubtitleOptions;
  subtitlesContent: any; // Text[] | SubtitleItem[],
  textColor: string;
  title: string;
  dimensionType: DimensionType;
  hidePromotion: boolean;
  font?: VideoTemplateFonts;
  durationInSeconds?: number;
}

const defaultProps: GenerateVideoParams = {
  title: '3 tips to land your dream job in Google :) 🔥',
  color: VideoTemplateColours.GRADIENT_LIGHT_PINK_YELLOW,
  displayName: 'Viren Mohindra',
  // TODO changed this for FullSizeRender from 1200 to 1400
  bannerUrl:
    'https://images.unsplash.com/photo-1719639945560-6d6275fc5ebf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyODc4NjZ8MHwxfHJhbmRvbXx8fHx8fHx8fDE3MTk5NTc0NjR8&ixlib=rb-4.0.3&q=80&w=1080',
  audioUrl:
    'https://jupitrr-ugc.s3-ap-southeast-1.amazonaws.com/kOU4NsOiHLPjC0inYn0CvcXGpXR2/e53f097f-d489-47de-bac2-9944b485c2c5.mp3', // https://jpt-staging-playground.s3-ap-southeast-1.amazonaws.com/36TCSpTx2gc2nuecGrlrgqntQlT2/0c25aeda-69b8-4b03-89f8-554496915635.mp3',
  avatarUrl:
    'https://jupitrr-staging-playground.s3.ap-southeast-1.amazonaws.com/6baa98cc1c3f4d76e989701746e322dd-removebg-preview.png',
  subtitlesContent: bigTitle, // bigTitle, emptyData
  handle: JUPITRR_HANDLE,
  subtitles: SubtitleOptions.BIG_TITLE,
  textColor: VideoTemplateColours.WHITE,
  dimensionType: DimensionType.portrait,
  hidePromotion: false,
  font: VideoTemplateFonts['Unbounded']
};

const isDev = false; // Set this as true for local development
export const Video: React.FC = (props) => {
  const inputProps: GenerateVideoParams = getRemotionEnvironment().isPlayer
    ? (props as any)
    : getInputProps() || {};
  useDefaultFont();
  const [handle] = useState(() => delayRender());
  const [duration, setDuration] = useState(1); // at the very minimum render a video for 10 seconds. (Please modify this for modifications)

  useEffect(() => {
    getVideoDuration().then();
  }, [inputProps]);

  const getVideoDuration = async () => {
    const url = isDev ? defaultProps.audioUrl : inputProps.audioUrl;
    let audioDuration = inputProps.durationInSeconds;
    if (!audioDuration) audioDuration = await getAudioDurationInSeconds(url);
    setDuration(Math.max(1, Math.round((audioDuration + 1) * FPS)));
    continueRender(handle);
  };
  const currentDimension: Dimension =
    (isDev
      ? getDimensionByName(defaultProps.dimensionType)
      : getDimensionByName(inputProps.dimensionType)) || DEFAULT_DIMENSIONS;

  useEffect(() => {
    if (inputProps.title !== 'Video Bug Tsz Hoi') return;
    const test: any = {};
    console.log(test.should.crash);
  }, [inputProps.title]);

  return (
    <>
      <Composition
        id={VideoTemplateOptions.DIAGONAL}
        component={DiagonalRender}
        durationInFrames={duration}
        fps={FPS}
        width={currentDimension.W}
        height={currentDimension.H}
        defaultProps={isDev ? defaultProps : undefined}
      />
      <Composition
        id={VideoTemplateOptions.CURVY_LINE}
        component={CurvyLineRender}
        durationInFrames={duration}
        fps={FPS}
        width={currentDimension.W}
        height={currentDimension.H}
        defaultProps={isDev ? defaultProps : undefined}
      />
      <Composition
        id={VideoTemplateOptions.PODART}
        component={PodArtRender}
        durationInFrames={duration}
        fps={FPS}
        width={currentDimension.W}
        height={currentDimension.H}
        defaultProps={isDev ? defaultProps : undefined}
      />
      <Composition
        id={VideoTemplateOptions.DEFAULT}
        component={DefaultRender}
        durationInFrames={duration}
        fps={FPS}
        width={currentDimension.W}
        height={currentDimension.H}
        defaultProps={isDev ? defaultProps : undefined}
      />
      <Composition
        id={VideoTemplateOptions.WITH_IMAGE}
        component={ImageRender}
        durationInFrames={duration}
        fps={FPS}
        width={currentDimension.W}
        height={currentDimension.H}
        defaultProps={isDev ? defaultProps : undefined}
      />
      <Composition
        id={VideoTemplateOptions.PRODUCT_HUNT}
        component={ProductHuntRender}
        durationInFrames={duration}
        fps={FPS}
        width={currentDimension.W}
        height={currentDimension.H}
        defaultProps={isDev ? defaultProps : undefined}
      />
      <Composition
        id={VideoTemplateOptions.FULL_SIZE}
        component={FullSizeRender}
        durationInFrames={duration}
        fps={FPS}
        width={currentDimension.W}
        height={currentDimension.H}
        defaultProps={isDev ? defaultProps : undefined}
      />
      <Composition
        id={VideoTemplateOptions.PODCAST}
        component={PodcastRender}
        durationInFrames={duration}
        fps={FPS}
        width={currentDimension.W}
        height={currentDimension.H}
        defaultProps={isDev ? defaultProps : undefined}
      />
    </>
  );
};
