import { StandardIconProps } from '../../../components/Shared/Icon';

const tick = (props: StandardIconProps) => {
  return (
    <svg
      width={(props.size || '16') + 'px'}
      height={(props.size || '16') + 'px'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3332 4L5.99984 11.3333L2.6665 8"
        stroke={props.fill || '#3C3FEF'}
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default tick;
