import { FONT_FAMILY, VideoTemplateFonts } from '../utils/constant';
import { Sequence } from 'remotion';
import { TextOutput, TextSequence } from '../components/TextSequence';
import { Title } from './Title';
import React from 'react';

export const DefaultOption: React.FC<{
  title: string;
  color: string;
  subtitlesContent: TextOutput[];
  font: VideoTemplateFonts;
}> = ({ title, color, subtitlesContent, font }) => (
  <>
    <Sequence from={0} layout="none">
      <Title
        text={title}
        color={color}
        style={{ fontFamily: FONT_FAMILY(font) }}
      />
    </Sequence>
    {subtitlesContent !== null && subtitlesContent.length > 0
      ? subtitlesContent.map((text, idx) => (
        <TextSequence
          key={idx}
          text={text}
          style={{
              width: '100%',
              position: 'absolute',
              bottom: 120,
              fontFamily: FONT_FAMILY(font)
            }}
          />
        ))
      : null}
  </>
);
