/**
 * Some defaults defined here can be overwritten by environment variables
 * Check lib/environments.ts
 */

// Cache control
export const fileCache: boolean = false;

// Dark mode
export type DarkMode = 'dark' | 'light' | null;
export const defaultMode: DarkMode = 'light';
export const overrideOS: boolean = true;

// Images
export const nextFeatureImages: boolean = true;
export const nextInlineImages: boolean = true;
export const imageQuality: number = 80;
export const sourceImages: boolean = false;

// RSS
export const rssFeed: boolean = true;

// Ghost Member Subscriptions
export const memberSubscriptions: boolean = true;

// Commenting system
export type CommentingSystem = 'commento' | 'disqus' | null;
export const commenting: CommentingSystem = null;

export const commentoUrl: string = 'https://cdn.commento.io'; // 'https://commento.your-blog.com'
export const disqusShortname: string = 'short-name-here';

// PrismJS
export const prism: boolean = true;
export const prismIgnoreMissing: boolean = true;

// Table of Contents
export const toc: boolean = true;
export const maxDepth: number = 2;

// Incremental Static Regenerations (ISR)
export const isr: boolean = false;
export const maxNumberOfPosts: number = 20;
export const maxNumberOfPages: number = 20;
