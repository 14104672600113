import { continueRender, delayRender } from 'remotion';
import { useEffect, useState } from 'react';

const timeoutPromise = new Promise((resolve) => setTimeout(resolve, 20000))

export const useDefaultFont = () => {
  const [waitForFont] = useState(() => delayRender());

  useEffect(() => {
    Promise.race([timeoutPromise, fetchFonts]).then((value) => {
      continueRender(waitForFont);
    }).finally(() => {
      continueRender(waitForFont);
    });
  }, [waitForFont]);

  const fetchFonts = new Promise(async (resolve, reject) => {
    const font = await new FontFace(
      'CircularStd-Bold',
      `url(https://jupitrr-staging-playground.s3.ap-southeast-1.amazonaws.com/CircularStd-Bold.ttf)`
    ).load();
    const font2 = await new FontFace(
      'CircularStd-Medium',
      `url(https://jupitrr-staging-playground.s3.ap-southeast-1.amazonaws.com/CircularStd-Medium.ttf)`
    ).load();
    document.fonts.add(font);
    document.fonts.add(font2);
    return resolve('fetchFont Success');
  });
};
