/*
 * Type
 */
interface Dimension {
  W: number;
  H: number;
}

enum DimensionType {
  'landscape' = 'landscape',
  'portrait' = 'portrait',
  'square' = 'square'
}

/*
 * Constant
 */
const DEFAULT_DIMENSIONS: Dimension = { W: 1080, H: 1080 };
const DIMENSIONS_OPTIONS: { [key in DimensionType]: Dimension } = {
  landscape: {
    W: 1920,
    H: 1080
  },
  portrait: {
    W: 1080,
    H: 1920
  },
  square: DEFAULT_DIMENSIONS
};

/*
 * Fns
 */
const getDimensionByName = (name: string): Dimension | null => {
  return DIMENSIONS_OPTIONS.hasOwnProperty(name)
    ? DIMENSIONS_OPTIONS[name]
    : null;
};

const horizontalSpaceMultiplier = (dimensionType: DimensionType): number => {
  return dimensionType === DimensionType.landscape ? 2 : 1;
};

export {
  DEFAULT_DIMENSIONS,
  DIMENSIONS_OPTIONS,
  DimensionType,
  horizontalSpaceMultiplier,
  getDimensionByName
};

export type { Dimension };
