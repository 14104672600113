import { SHOW_CHATBASE_ON_PLANS } from '@/slideshow/utils/constant';
import { useEffect, useState } from 'react';
import { useUser } from '@/hooks/useUser';

export const ChatbotPopup = () => {
  const { hasPremium } = useUser();

  useEffect(() => {
    const chatbaseButton = document.getElementById('chatbase-bubble-button');
    const chatbaseBubbles = document.getElementById('chatbase-message-bubbles');
    
    // Hide elements for premium users
    if (hasPremium !== null && SHOW_CHATBASE_ON_PLANS.includes(hasPremium)) {
      if (chatbaseButton) chatbaseButton.style.display = 'none';
      if (chatbaseBubbles) chatbaseBubbles.style.display = 'none';
    }

    const script = document.createElement('script');

    if (hasPremium === null)return;
    if (hasPremium !== null && SHOW_CHATBASE_ON_PLANS.includes(hasPremium)) {
      // Load Tidio for premium users
      script.src = '//code.tidio.co/mztviuzwwhn6zggv0baiwl2c9fiipdhx.js';
      console.log('Tidio script loaded for premium user');
    } else {
      // Load Chatbase for non-premium users
      script.src = 'https://www.chatbase.co/embed.min.js';
      script.dataset.chatbotId = 'tKLu8vkMXRGg528pHobuO';
      script.dataset.domain = 'www.chatbase.co';
      script.defer = true;
      console.log('Chatbase script loaded for non-premium user');
    }

    if (script.src) {
      document.body.appendChild(script);
    }

    return () => {
      if (script.src) {
        document.body.removeChild(script);
      }
    };
  }, [hasPremium]);

  return (
    <>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.embeddedChatbotConfig = {
              chatbotId: "tKLu8vkMXRGg528pHobuO",
              domain: "www.chatbase.co"
            };
          `
        }}
      />
    </>
  );
};

export default ChatbotPopup;