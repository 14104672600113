import { StandardIconProps } from '../../../components/Shared/Icon';

const search = (props: StandardIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="8" cy="8" r="5.5"></circle>
      <line x1="15.5" y1="15.5" x2="13" y2="13"></line>
    </svg>
  );
};

export default search;
