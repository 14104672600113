import { API_URL } from './constant';
import { LocalStorageItems, getItem } from '../utils/generalUtilities';
import User, { UserInfo } from './User';
import fetchWrapper, { MethodType } from '../utils/fetchWrapper';

enum SIB_EVENTS {
  SIGNUP = 'signup', // used in users-firebase
  PURCHASE = 'purchase',
  SLIDESHOW_UPLOAD_FILE = 'ai_video__upload_file',
  SLIDESHOW_EXPORT = 'ai_video__export',
  VISUALS_ERROR = 'stock_visuals__error'
}

const Email = {
  name: '/emails',
  route: '',
  /**
   * Sends email via sendinblue, using the specified template_id.
   * params is the relevant information that needs to be passed from the front-end, for eg:
   * params.share_video_url for the s3 download video link.
   * @param user_id
   * @param template_id
   * @param params
   */
  sendEmail(user_id: string, template_id: number, params?: any): Promise<any> {
    this.route = `${API_URL}/${this.name}/${user_id}`;
    const body = JSON.stringify({ template_id, params });
    return fetchWrapper(this.route, MethodType.POST, body);
  },
  async sendPurchaseSuccessEmail(user: UserInfo) {
    if (window.sendinblue) {
      const me = await User.me(getItem(LocalStorageItems.ACCESS_TOKEN));
      window.sendinblue.identify(me.email, {
        FIRSTNAME: user.display_name,
        id: user.id
      });
      window.sendinblue.track(
        'purchase',
        {},
        {
          data: {
            display_name: user.display_name
          }
        }
      );
    }
  },
  async sendWaitlistSuccessEmail(email: string) {
    if (!window.sendinblue) return;
    window.sendinblue.identify(email);
    window.sendinblue.track('waitlist_joined');
  },
  async track(event: SIB_EVENTS, data?: any) {
    if (!window.sendinblue) return;
    window.sendinblue.track(event, undefined, data || {});
  }
};

export default Email;

export { SIB_EVENTS };
