import { ExternalUrls, PageNames } from '../../utils/generalUtilities';
import { P1 } from '../OldGeneralComponents';
import { PRICING_SOURCE } from '../../audiogram/utils/constant';
import Link from 'next/link';
import ProductsTab from './ProductsTab';

const DesktopLinks = () => (
  <div className="flex flex-row">
    <ProductsTab />
    <Link
      className="mr-[7px] rounded-md px-[18px] py-[10px] text-grey-500 hover:bg-blue-50"
      passHref={true}
      href={`${PageNames.PRICING}?source=${PRICING_SOURCE.header}`}
    >
      <P1>Pricing</P1>
    </Link>
    <Link
      className="mr-[7px] rounded-md px-[18px] py-[10px] text-grey-500 hover:bg-blue-50"
      passHref={true}
      target="_blank"
      href={ExternalUrls.AFFILIATE}
    >
      <P1>Affiliate</P1>
    </Link>
    <Link
      className="mr-[7px] rounded-md px-[18px] py-[10px] text-grey-500 hover:bg-blue-50"
      passHref={true}
      target="_blank"
      href={`${PageNames.CHANGE_LOG}`}
    >
      <P1>Changelog</P1>
    </Link>
    <Link
      className="mr-[7px] rounded-md px-[18px] py-[10px] text-grey-500 hover:bg-blue-50"
      passHref={true}
      href={`${PageNames.BLOG}`}
    >
      <P1>Blog</P1>
    </Link>
  </div>
);

export default DesktopLinks;
