import './fonts.module.css';
import React from 'react';

const baseFontFamily =
  'CircularStd, CircularStd-Bold, Noto Sans JP, Noto Sans KR, Noto Sans TC, Noto Sans Telugu, sans-serif';

const FONT_FAMILY = (font?: VideoTemplateFonts) =>
  font && font.length && font !== VideoTemplateFonts['Circular Std']
    ? `${font}, ${baseFontFamily}`
    : `${baseFontFamily}`;

const fontFamilyLight =
  'CircularStd, CircularStd-Medium, Noto Sans JP, Noto Sans KR, Noto Sans TC, Noto Sans Telugu, sans-serif';

const FONT_FAMILY_LIGHT = (font?: VideoTemplateFonts) =>
  font && font.length && font !== VideoTemplateFonts['Circular Std']
    ? `${font}, ${fontFamilyLight}`
    : `${fontFamilyLight}`;

export enum VideoTemplateFonts {
  'Circular Std' = 'Circular Std',
  'Unbounded' = 'Unbounded',
  'Solitreo' = 'Solitreo',
  'Playfair Display' = 'Playfair Display',
  'Quicksand' = 'Quicksand',
  'Lexend' = 'Lexend',
  'Dancing Script' = 'Dancing Script'
}

const JUPITRR_HANDLE = '@jupitrr_ai';

const normalFontSize: React.CSSProperties = {
  lineHeight: '1.2em',
  fontSize: 64
};

const smallerFontSize: React.CSSProperties = {
  lineHeight: '1.3em',
  fontSize: 64
};

const PRICING_SOURCE = {
  header: 'header',
  footer: 'footer',
  dashboard: 'profile-dashboard-page',
  'settings-page': 'settings-page',
  'single-video-length-modal': 'single-video-length-modal',
  'remove-watermark': 'remove-watermark-modal',
  'monthly-total-video-limit-modal': 'monthly-total-video-limit-modal',
  'transcription-limit-modal': 'transcription-limit-modal'
};

export {
  FONT_FAMILY,
  FONT_FAMILY_LIGHT,
  fontFamilyLight,
  JUPITRR_HANDLE,
  normalFontSize,
  smallerFontSize,
  PRICING_SOURCE
};
