import { Audio, Sequence } from 'remotion';
import { EMPTY_AUDIO } from '../../slideshow/utils/constant';
import { cacheS3Url } from '../../utils/s3';
import React, { useState } from 'react';

interface AudioContainerProps {
  from: number;
  audioUrl: string;
}

const AudioContainer = (props: AudioContainerProps) => {
  const [audioUrl, setAudioUrl] = useState(cacheS3Url(props.audioUrl));
  return (
    <Sequence from={props.from} style={{ zIndex: '-1' }}>
      <Audio
        onError={(e) => {
          console.error('eror spotted getting audio');
          console.error(e);
          setAudioUrl(EMPTY_AUDIO);
        }}
        src={audioUrl}
      />
    </Sequence>
  );
};

export default AudioContainer;
