import { Colours } from '../Colours';
import DummyAvatar from 'boring-avatars';
import ImageUpload from './ImageUpload';
import React from 'react';

interface AvatarProps {
  src?: string;
  size?: number; // default size is 70.
  onClick?: () => void;
  hasBorder?: boolean;
  borderColor?: string;
  isEditable?: boolean;
  onFileChange?: (e: any) => void;
  style?: React.CSSProperties;
  userName?: string;
}

const Avatar = ({
  src,
  style = {},
  size = 70,
  onClick,
  hasBorder,
  borderColor = Colours.BrightYellow,
  isEditable = false,
  onFileChange,
  userName
}: AvatarProps): JSX.Element => (
  <div
    onClick={onClick}
    style={Object.assign(
      {
        display: 'flex',
        position: 'relative',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: isEditable ? 'pointer' : 'default',
        border: hasBorder ? `3px solid ${borderColor}` : null,
        borderRadius: (size * 1.1) / 2
        // marginBottom: hasBorder ? 10 : null
      },
      style
    )}
  >
    {src ? (
      <img
        alt={'profile image'}
        src={src}
        style={{
          objectFit: 'cover',
          alignSelf: 'center',
          height: size,
          width: size,
          borderRadius: size / 2
        }}
      />
    ) : (
      <DummyAvatar
        size={40}
        variant="beam"
        name={userName}
        colors={['#92A1C6', '#146A7C', '#F0AB3D', '#C271B4', '#C20D90']}
      />
    )}
    {isEditable ? (
      <>
        <input
          id={isEditable ? 'upload-avatar' : null}
          multiple={false}
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={(e) => onFileChange(e)}
        />
        <ImageUpload size={size} />
      </>
    ) : null}
  </div>
);

export default Avatar;
