import { Colours, Gradient, Shadows } from './Colours';
import Icon, { IconType } from './Shared/Icon';
import Loading, { LoadingColor } from './Loading';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

/*
 *
 *  300 = Book
 *  500 = Medium
 *  700 = Bold
 *  900 = Black
 *
 * */

type CircularFontWeight = '300' | '500' | '700' | '900';

interface FontInterface {
  fontWeight: CircularFontWeight;
}

const AutoGradientTextColor = css<{ style?: React.CSSProperties }>`
  ${(props) => {
    if (props.style && props.style.color) {
      return `
      background: -webkit-${props.style.color};
      background: ${props.style.color};
      -webkit-text-fill-color: rgba(255, 255, 255, 0);
      -webkit-background-clip: text;
  `;
    } else return '';
  }}
`;

const getAutoGradientTextStyle = (style: React.CSSProperties) => {
  const gradientStyle = {
    ...style
  };

  if (style.color) {
    gradientStyle.WebkitTextFillColor = 'rgba(255, 255, 255, 0)';
    gradientStyle.WebkitBackgroundClip = 'text';
    gradientStyle.background = style.color;
  }

  return gradientStyle;
};

const H0 = styled.h1`
  font-weight: 700;
  font-style: normal;
  font-size: 59.5px;
  line-height: 64px;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
  }
  @media (max-width: 400px) {
    font-size: 26px;
    line-height: 30px;
  }
  ${AutoGradientTextColor}
`;

const H1 = styled.h1`
  font-weight: 700;
  font-style: normal;
  font-size: 36px;
  line-height: 46px;
  text-transform: initial;
  ${AutoGradientTextColor}
  @media (max-width: 800px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

/* Confirmed breakpoint for responsive is 800 from hoi */

const H2 = styled.h2<FontInterface>`
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 30px;
  text-transform: initial;
  color: ${Colours.DarkGrey};
  ${AutoGradientTextColor}
  @media (max-width: 800px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

const H2Medium = styled.h2`
  font-weight: 500;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-transform: initial;
  color: ${Colours.DarkGrey};
  ${AutoGradientTextColor}
`;

const H3 = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-transform: initial;
  color: ${Colours.DarkGrey};
  ${AutoGradientTextColor}
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

interface P1Props {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

// Once all P1's style properties are mirated to tailwind classNames,
// then we should remove style property
const P1: FC<P1Props> = ({ children, className, style, onClick }) => (
  <div
    className={`text-base font-light not-italic leading-[22px] ${className}`}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);

const P2 = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-transform: initial;
  color: ${Colours.MediumGrey};
  ${AutoGradientTextColor}
`;

const P3 = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 13px;
`;

interface NewP3Props {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const Input = styled.input`
  height: 50px;
  width: 400px;
  background: ${Colours.White};
  border: 2px solid ${Colours.OffWhite};
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 17px;
  padding: 5px;
  margin-bottom: 5px;
  padding-left: 20px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Span = styled.span`
  color: ${Colours.LightGrey};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const Header = styled.h1`
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
  margin-bottom: 10px;
  @media (max-width: 900px) {
    line-height: 1;
  }
`;
const SubHeader = styled.h2`
  color: '#000';
  margin-top: 15px;
  font-size: 17px;
  margin-bottom: 25px;
`;

const Section = styled.div`
  margin-bottom: 6rem;
  margin-top: 6rem;
  position: relative;
  @media (max-width: 800px) {
    margin-bottom: 48px;
  }
`;

const SectionContent = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${(props) => props.maxWidth || '918px'};
  width: 100%;
  position: relative;
  z-index: 1;
  @media (max-width: 1199px) {
    padding-right: 12px;
    padding-left: 12px;
  }
`;

const SectionGradientBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 0;
`;

const Card = styled.div`
  background: ${Gradient.White};
  box-shadow: ${Shadows.Black};
  border-radius: 20px;
`;

enum ButtonVariant {
  'blue' = 'blue',
  'black' = 'black',
  'blue__inverted' = 'blue__inverted',
  'yellow' = 'yellow',
  'white' = 'white',
  'black__inverted' = 'black__inverted',
  'white__blue' = 'white__blue',
  'danger__inverted' = 'danger__inverted',
  'orange' = 'orange',
  'transparent' = 'transparent'
}

const JPNativeButton = styled.div`
  appearance: none;
  -webkit-appearance: none;
  display: flex;
  position: relative;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 46px;
  border-radius: 100px;
  font-size: 16px;
  width: 260px;
  line-height: 22px;
  cursor: pointer;
  font-weight: 300;
  pointerevents: ${(props) => (props.isLoading ? 'none' : 'all')};
  flex-flow: ${(props) => (props.iconLeft ? 'row-reverse' : 'row')};
  &:hover {
    transform: scale(1.05);
    color: ${(props) => props.textColor};
  }
  @media (max-width: 600px) {
    width: 100%;
  }
  color: ${(props) => props.textColor};
  background: ${(props) => props.backgroundColor};
  border: 2px solid ${(props) => props.borderColor};

  ${(props) =>
    props.elevated &&
    css`
      box-shadow: 0px 2px 30px rgba(174, 174, 174, 0.2);
    `}

  ${(props) =>
    props.unclickable &&
    css`
      &:hover {
        transform: scale(1);
        cursor: default;
      }
    `}

  ${(props) =>
    props.isLoading &&
    css`
      pointer-events: none;
    `}

  ${(props) =>
    props.disabled &&
    css`
      background: ${Colours.OffWhite};
      border-color: ${Colours.OffWhite};
      color: ${Colours.LightGrey};
      &:hover {
        color: ${Colours.LightGrey};
        transform: scale(1);
        cursor: default;
      }
    `}
`;

interface JPButtonProps {
  text: string;
  isLoading?: boolean;
  variant?: ButtonVariant; // transparent rounded button
  style?: React.CSSProperties;
  disabled?: boolean;
  unclickable?: boolean;
  onClick?: () => void;
  icon?: string;
  iconLeft?: boolean;
  iconSize?: number;
  elevated?: boolean;
  className?: string;
  link?: string;
  id?: string;
  loadingWithText?: boolean;
  type?: string;
}

const JPButton = (props: JPButtonProps) => {
  const {
    text,
    isLoading,
    icon,
    iconLeft,
    iconSize = 18,
    link,
    variant = ButtonVariant.blue,
    id
  } = props;

  let [textColor, setTextColor] = useState(Colours.White);
  let [backgroundColor, setBackgroundColor] = useState(Gradient.Blue);
  let [borderColor, setBorderColor] = useState(Colours.Transparent);
  const setVariantStyle = () => {
    switch (variant) {
      case ButtonVariant.black:
        setTextColor(Colours.White);
        setBackgroundColor(Colours.Black);
        setBorderColor(Colours.Transparent);
        break;
      case ButtonVariant.blue:
        setTextColor(Colours.White);
        setBackgroundColor(Gradient.Blue);
        setBorderColor(Colours.Transparent);
        break;
      case ButtonVariant.blue__inverted:
        setTextColor(Colours.MainBlue);
        setBackgroundColor(Colours.White);
        setBorderColor(Colours.MainBlue);
        break;
      case ButtonVariant.black__inverted:
        setTextColor(Colours.Black);
        setBackgroundColor(Colours.White);
        setBorderColor(Colours.Black);
        break;
      case ButtonVariant.white__blue:
        setTextColor(Colours.MainBlue);
        setBackgroundColor(Colours.White);
        setBorderColor(Colours.White);
        break;
      case ButtonVariant.yellow:
        setTextColor(Colours.DarkGrey);
        setBackgroundColor(Colours.SelectedYellow);
        setBorderColor(Colours.SelectedYellow);
        break;
      case ButtonVariant.orange:
        setTextColor(Colours.White);
        setBackgroundColor(Colours.ProOrange);
        setBorderColor(Colours.ProOrange);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setVariantStyle();
  }, []);

  const JPButtonContent = () => (
    <>
      {isLoading && props.loadingWithText ? (
        <>
          {text}
          <Loading
            colorType={LoadingColor.white}
            style={{ marginLeft: 12 }}
            size={30}
          />
        </>
      ) : null}
      {isLoading && !props.loadingWithText ? (
        <Loading colorType={LoadingColor.white} size={30} />
      ) : null}
      {!isLoading ? (
        <>
          {text}
          {icon ? (
            <div
              style={{
                display: 'flex',
                marginLeft: iconLeft ? 0 : 12,
                marginRight: iconLeft ? 12 : 0
              }}
            >
              <Icon type={icon as IconType} size={iconSize} color={textColor} />
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );

  if (link) {
    return (
      <a id={id} href={link} {...props}>
        <JPNativeButton
          textColor={textColor}
          backgroundColor={backgroundColor}
          borderColor={borderColor}
        >
          <JPButtonContent />
        </JPNativeButton>
      </a>
    );
  }

  return (
    <JPNativeButton
      id={id}
      textColor={textColor}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      {...props}
    >
      <JPButtonContent />
    </JPNativeButton>
  );
};

const Image = styled.img`
  height: 145px;
  width: 145px;
  border-radius: 72.5px;
  border: 5px solid ${Colours.BrightYellow};
`;

const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
`;

//input and button container
const InteractionContainer = styled.div.attrs(() => ({
  className: 'interaction-container'
}))`
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: ${(props) => props.maxWidth || '400px'};
  padding-left: 12px;
  padding-right: 12px;
  margin: ${(props) => props.margin || 'auto'};
  text-align: center;
  align-items: ${(props) => props.alignItems || 'center'};
  @media (max-width: 600px) {
    padding: unset;
    width: 90%;
  }
`;

interface TransparentClickableModalProps {
  onClick: () => void;
}

const TransparentClickableModal = (props: TransparentClickableModalProps) => (
  <div
    onClick={props.onClick}
    style={{
      position: 'fixed',
      backgroundColor: 'transparent', // Colours.Black,
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 2
    }}
  />
);

export {
  H0,
  H1,
  H2,
  H2Medium,
  H3,
  P1,
  P2,
  P3,
  Span,
  Input,
  Header,
  SubHeader,
  Section,
  SectionContent,
  SectionGradientBackground,
  Card,
  ButtonVariant,
  JPNativeButton,
  JPButton,
  CenteredContainer,
  InteractionContainer,
  TransparentClickableModal
};
