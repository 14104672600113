// type data: {
//     route: string,
//     type: string, //GET || POST
//     body: string,
//     dateCreated: any,
// };
import { firestore } from './firebase';

interface ErrorData {
  route?: string;
  method?: string;
  error?: {};
  code?: string;
  message?: string;
  dateCreated?: Date;
}

const Errors = {
  name: 'tracking_errors',

  catcher(route = '', method = '', error = {}): void {
    const postData: ErrorData = {
      route,
      method,
      error
    };

    this.add(postData);

    console.log('======ERROR CATCHER ======');
    console.log(postData);
  },

  add(data: ErrorData): void {
    data.dateCreated = new Date();
    setTimeout(() => {
      firestore
        .collection(this.name)
        .add(data)
        .then(() => {
          // alert("success tracking")
          console.log('tracking added');
        })
        .catch((err) => {
          console.log(err);
          // if (process.env.NODE_ENV === 'production' && Sentry) {
          //   Sentry.captureException(err);
          // }
        });
    }, 3000);
  }
};

export default Errors;
