import * as appConfig from './appConfig';

// siteUrl, platform, ghostAPIUrl, ghostAPIKey must be defined here
export const ghostAPIUrl =
  process.env.CMS_GHOST_API_URL || 'https://jupitrr.ghost.io';
export const ghostAPIKey =
  process.env.CMS_GHOST_API_KEY || 'a692992374cdff74ab4e03afe6';

const siteUrl =
  process.env.SITE_URL ||
  (process.env.VERCEL_URL && `https://${process.env.VERCEL_URL}`) ||
  process.env.URL ||
  'http://localhost:3000';

const platform = (process.env.NETLIFY === 'true' && 'netlify') || 'vercel';

// Environment variables that can be used to override the defaults in appconfig.js
const resolveBool = (value: string | undefined, defaultValue: boolean) => {
  if (!value) return defaultValue;
  return value === 'true';
};

const resolveNumber = (value: string | undefined, defaultValue: number) => {
  if (!value) return defaultValue;
  return parseInt(value, 10);
};

const resolveDarkMode = (
  value: string | undefined,
  defaultValue: appConfig.DarkMode
) => {
  if (!value) return defaultValue;
  if (value === 'dark') return 'dark';
  return 'light';
};

export interface ProcessEnvProps {
  siteUrl: string;
  platform: string;
  darkMode: {
    defaultMode: appConfig.DarkMode;
    overrideOS: boolean;
  };
  nextImages: {
    feature: boolean;
    inline: boolean;
    quality: number;
    source: boolean;
  };
  rssFeed: boolean;
  memberSubscriptions: boolean;
  commenting: {
    system: appConfig.CommentingSystem;
    commentoUrl: string;
    disqusShortname: string;
  };
  prism: {
    enable: boolean;
    ignoreMissing: boolean;
  };
  toc: {
    enable: boolean;
    maxDepth: number;
  };
  isr: {
    enable: boolean;
    maxNumberOfPosts: number;
    maxNumberOfPages: number;
  };
}

export const processEnv: ProcessEnvProps = {
  siteUrl,
  platform,
  darkMode: {
    defaultMode: resolveDarkMode(
      process.env.DARK_MODE_DEFAULT,
      appConfig.defaultMode
    ),
    overrideOS: resolveBool(
      process.env.DARK_MODE_OVERRIDE_OS,
      appConfig.overrideOS
    )
  },
  nextImages: {
    feature: resolveBool(
      process.env.NEXT_FEATURE_IMAGES,
      appConfig.nextFeatureImages
    ),
    inline: resolveBool(
      process.env.NEXT_INLINE_IMAGES,
      appConfig.nextInlineImages
    ),
    quality: resolveNumber(
      process.env.NEXT_IMAGES_QUALITY,
      appConfig.imageQuality
    ),
    source: resolveBool(process.env.NEXT_SOURCE_IMAGES, appConfig.sourceImages)
  },
  rssFeed: resolveBool(process.env.RSS_FEED, appConfig.rssFeed),
  memberSubscriptions: resolveBool(
    process.env.MEMBER_SUBSCRIPTIONS,
    appConfig.memberSubscriptions
  ),
  commenting: {
    system:
      (process.env.COMMENTING_SYSTEM as appConfig.CommentingSystem) ||
      appConfig.commenting,
    commentoUrl: process.env.COMMENTO_URL || appConfig.commentoUrl,
    disqusShortname: process.env.DISQUS_SHORTNAME || appConfig.disqusShortname
  },
  prism: {
    enable: resolveBool(process.env.PRISM, appConfig.prism),
    ignoreMissing: resolveBool(
      process.env.PRISM_IGNORE_MISSING,
      appConfig.prismIgnoreMissing
    )
  },
  toc: {
    enable: resolveBool(process.env.TOC, appConfig.toc),
    maxDepth: resolveNumber(process.env.TOC_MAX_DEPTH, appConfig.maxDepth)
  },
  isr: {
    enable: resolveBool(process.env.NEXT_ISR, appConfig.isr),
    maxNumberOfPosts: resolveNumber(
      process.env.NEXT_ISR_MAX_NUMBER_POSTS,
      appConfig.maxNumberOfPosts
    ),
    maxNumberOfPages: resolveNumber(
      process.env.NEXT_ISR_MAX_NUMBER_PAGES,
      appConfig.maxNumberOfPages
    )
  }
};
