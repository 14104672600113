import { MAX_NUMBER_OF_LINES } from '../Video';
import { TextOutput } from '../components/TextSequence';
import { interpolate, useCurrentFrame, useVideoConfig } from 'remotion';

const createSpotifySubtitleArray = (
  subtitlesContent: TextOutput[]
): TextOutput[][] => {
  if (!subtitlesContent || subtitlesContent.length === 0) return [];
  let collatedSubs: TextOutput[][] = [];
  for (let i = 0; i < subtitlesContent.length; i += MAX_NUMBER_OF_LINES)
    collatedSubs.push(subtitlesContent.slice(i, i + MAX_NUMBER_OF_LINES));
  return collatedSubs;
};

const useCalculateOpacity = () => {
  const frame = useCurrentFrame();
  const videoConfig = useVideoConfig();
  return interpolate(
    frame,
    [videoConfig.durationInFrames - 25, videoConfig.durationInFrames - 15],
    [1, 0],
    {
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp'
    }
  );
};

export { createSpotifySubtitleArray, useCalculateOpacity };
