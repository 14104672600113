import { Colours } from '../../components/Colours';
import { normalFontSize } from '../utils/constant';
import React from 'react';

export const Title: React.FC<{
  text: string;
  color: string;
  isDefaultTextColor: boolean;
  style: any;
}> = ({ text, color, isDefaultTextColor, style }) => (
  <div
    style={Object.assign(
      {
        ...normalFontSize,
        fontSize: 64,
        color: isDefaultTextColor ? Colours.Black : color,
        textAlign: 'center',
        paddingLeft: 20,
        paddingRight: 20
      },
      style
    )}
  >
    {text}
  </div>
);
