import { createContext, useEffect } from 'react';
import { useContext } from 'react';
import React, { useMemo, useState } from 'react';

import Modal from '../components/Modals/BaseModal';
export const ModalContext = createContext<ModalContextValue | undefined>(
  undefined
);

export interface ModalContextValue {
  openModal: (
    newChildren: Element | HTMLElement | string | JSX.Element,
    options?: {
      width?: number;
      height?: number;
      className?: string;
      onClose?: () => void;
      onOpen?: () => void;
      style?: any;
      transparentBackdrop?: boolean;
      showCrossButton?: boolean;
      shouldCloseOnOverlayClick?: boolean;
    }
  ) => void;
  closeModal: () => void;
  isVisible: boolean;
}

export const ModalProvider = (props: any) => {
  const [visible, setVisibility] = useState(false);
  const [children, setChildren] = useState<
    HTMLElement | Element | string | JSX.Element
  >();
  const [onCloseModal, setOnCloseModal] = useState<() => void>(undefined);
  const [onOpen, setOnOpen] = useState<() => void>(undefined);
  const [showCrossButton, setShowCrossButton] = useState<boolean>(true);
  const [transparentBackdrop, setTransparentBackdrop] =
    useState<boolean>(false);
  const [width, setWidth] = useState<string | number>();
  const [height, setHeight] = useState<string | number>();
  const [style, setStyle] = useState<any>();
  const [className, setClassName] = useState<string>();
  const [shouldCloseOnOverlayClick, setShouldCloseOnOverlayClick] =
    useState<boolean>(true);

  const modalContext: ModalContextValue = useMemo(
    () => ({
      openModal: (newChildren, options) => {
        setVisibility(true);
        setChildren(newChildren);
        if (!options) return;
        if (options.onClose) setOnCloseModal(options.onClose);
        if (options && options.transparentBackdrop)
          setTransparentBackdrop(options.transparentBackdrop);
        if (options && options.style) setStyle(options.style);
        if (options && options.width) setWidth(options.width);
        if (options && options.height) setHeight(options.height);
        if (options && options.className) setClassName(options.className);
        if (options && typeof options.shouldCloseOnOverlayClick !== undefined)
          setShouldCloseOnOverlayClick(options.shouldCloseOnOverlayClick);
        if (options && typeof options.showCrossButton !== undefined)
          setShowCrossButton(options.showCrossButton);
      },
      closeModal: () => {
        setVisibility(false);
        setChildren(undefined);
        if (onCloseModal) onCloseModal();
        setOnOpen(undefined);
        setOnCloseModal(undefined);
        setShowCrossButton(undefined);
      },
      isVisible: visible
    }),
    [visible]
  );

  useEffect(() => {
    if (!children) return;
    if (onOpen) onOpen();
  }, [children, onOpen]);

  return (
    <ModalContext.Provider value={modalContext}>
      {props.children}
      <Modal
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        showCrossButton={showCrossButton}
        style={style}
        width={width}
        height={height}
        className={className}
        transparentBackdrop={transparentBackdrop}
      >
        {children}
      </Modal>
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalContext');
  }
  return context;
};
