import { Colours } from '../../components/Colours';
import { Img } from 'remotion';
import { JUPITRR_HANDLE, fontFamilyLight } from '../utils/constant';
import React, { useState } from 'react';

const JUPITRR_WATERMARK = () => {
  const [logo, setLogo] = useState(
    'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/white-logo.svg'
  );
  return (
    <Img
      style={{ height: 50 }}
      alt="Made with Jupitrr"
      src={
        logo ||
        'https://jupitrr-admin.s3.ap-southeast-1.amazonagws.com/website/landing/blank-white.png'
      }
      onError={() =>
        setLogo(
          'https://jupitrr-admin.s3.ap-southeast-1.amazonagws.com/website/landing/blank-white.png'
        )
      }
    />
  );
};

export const Footer: React.FC<{
  handle: string;
  color: string;
  style?: React.CSSProperties;
  horizontalPadding?: number;
  hidePromotion: boolean;
}> = ({ handle, color, style, horizontalPadding = 48, hidePromotion }) => (
  <div
    style={Object.assign(
      {
        width: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: Colours.White,
        fontFamily: fontFamilyLight,
        fontSize: 24,
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
        bottom: 60,
        left: 0
      },
      style
    )}
  >
    <div>
      {handle && handle.length ? handle : hidePromotion ? ' ' : JUPITRR_HANDLE}
    </div>
    {hidePromotion ? ' ' : JUPITRR_WATERMARK()}
  </div>
);
