import { BigTitleOption } from './BigTitleOption';
import { Colours } from '../../components/Colours';
import {
  DEFAULT_DIMENSIONS,
  getDimensionByName,
  horizontalSpaceMultiplier
} from '../utils/dimension';
import { DefaultOption } from './DefaultOption';
import { FONT_FAMILY } from '../utils/constant';
import { Footer } from '../components/Footer';
import { GenerateVideoParams } from '../Video';
import { Img, Sequence } from 'remotion';
import { NewAudioVisualization } from '../components/NewAudioVisualization';
import { SubtitleItem } from '../components/Subtitles';
import { SubtitleOptions } from '../../types/MediaManager';
import { TextOutput } from '../components/TextSequence';
import { WordByWordOption } from './WordByWordOption';
import {
  createSpotifySubtitleArray,
  useCalculateOpacity
} from '../utils/general';
import { getHexCodeFromColourName } from '../../utils/colour';
import AudioContainer from '../components/AudioContainer';
import React, { FunctionComponent, useEffect, useState } from 'react';

export const DiagonalRender: FunctionComponent<any> = (
  props: GenerateVideoParams
) => {
  const {
    title,
    audioUrl,
    avatarUrl,
    bannerUrl,
    subtitles,
    subtitlesContent,
    handle,
    color,
    textColor,
    dimensionType,
    hidePromotion,
    font
  } = props;

  const hexCode = getHexCodeFromColourName(color);
  const isDefault = hexCode === Colours.White;

  const hexCodeTextColor = getHexCodeFromColourName(textColor);

  let collatedSubs: TextOutput[][] = [];
  if (subtitles === SubtitleOptions.BIG_TITLE)
    collatedSubs = createSpotifySubtitleArray(subtitlesContent as TextOutput[]);

  const [avatar, setAvatar] = useState(
    avatarUrl ||
      'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
  );
  const [banner, setBanner] = useState(
    bannerUrl ||
      'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
  );

  useEffect(() => {
    if (avatarUrl) setAvatar(avatarUrl);
  }, [avatarUrl]);

  useEffect(() => {
    if (bannerUrl) setBanner(bannerUrl);
  }, [bannerUrl]);

  const getSelectedSubtitleConfiguration = () => {
    switch (subtitles) {
      case SubtitleOptions.CC:
      case SubtitleOptions.NONE:
        return (
          <DefaultOption
            subtitlesContent={subtitlesContent as TextOutput[]}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
      case SubtitleOptions.BIG_TITLE:
        return (
          <BigTitleOption
            collatedSubs={collatedSubs}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
      case SubtitleOptions.WORD_BY_WORD:
        return (
          <WordByWordOption
            title={title}
            font={font}
            color={hexCodeTextColor}
            subtitlesContent={subtitlesContent as SubtitleItem[]}
          />
        );
      default:
        return (
          <DefaultOption
            subtitlesContent={subtitlesContent as TextOutput[]}
            color={hexCodeTextColor}
            title={title}
            font={font}
          />
        );
    }
  };

  const templateHeight = getDimensionByName(dimensionType).H;
  const bannerHeight = templateHeight * 0.65;

  const diagonalBoxStyle = (): React.CSSProperties => {
    if (dimensionType === 'portrait')
      return {
        position: 'absolute',
        background: hexCode,
        height: '100%',
        width: '100%',
        transform: 'rotate(48deg) scale(2.2)',
        right: '-63%',
        top: '20%',
        zIndex: 1
      };
    return {
      position: 'absolute',
      background: hexCode,
      height: '100%',
      width: '100%',
      transform: 'rotate(-35deg) scale(1.8)',
      right: '-31%',
      top: '16%',
      zIndex: 1
    };
  };

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '100%'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          fontFamily: FONT_FAMILY(font)
        }}
      >
        <AudioContainer from={0} audioUrl={audioUrl} />
        <Sequence from={0} layout="none">
          <div
            style={{
              display: 'flex',
              width: '100%',
              zIndex: -1,
              height: 'auto',
              position: 'absolute'
            }}
          >
            <Img
              alt="header banner"
              style={{
                objectFit: 'cover',
                height:
                  dimensionType === 'portrait'
                    ? bannerHeight
                    : getDimensionByName(dimensionType).H,
                width:
                  dimensionType === 'portrait'
                    ? '100%'
                    : getDimensionByName(dimensionType).W
              }}
              onError={(event) =>
                setBanner(
                  'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
                )
              }
              src={
                banner ||
                'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
              }
            />
          </div>
        </Sequence>
        <Sequence from={0} layout="none">
          <div
            style={{
              position: 'relative',
              height: '100%',
              width: '100%',
              marginTop: bannerHeight * 0.7
            }}
          >
            <div style={diagonalBoxStyle()}></div>
            <div
              style={{
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'flex-end',
                justifyContent: 'flex-start',
                width: '100%',
                position: 'relative',
                height: DEFAULT_DIMENSIONS.H * 0.6,
                paddingLeft: 48 * horizontalSpaceMultiplier(dimensionType),
                paddingRight: 48 * horizontalSpaceMultiplier(dimensionType),
                zIndex: 2,
                marginTop: -20,
                opacity: useCalculateOpacity()
              }}
            >
              <NewAudioVisualization
                audioUrl={audioUrl}
                isDefault={isDefault}
                waveColor={hexCodeTextColor}
                style={{ height: 170 }}
              />
              <div
                style={{
                  height: 340,
                  display: 'flex',
                  alignItems: 'flex-start',
                  maxWidth: 1152,
                  paddingLeft: 196
                }}
              >
                {getSelectedSubtitleConfiguration()}
              </div>
            </div>
          </div>
          <Footer
            horizontalPadding={48 * horizontalSpaceMultiplier(dimensionType)}
            handle={handle}
            hidePromotion={hidePromotion}
            color={hexCodeTextColor}
            style={{
              zIndex: 2
            }}
          />
        </Sequence>
      </div>
    </div>
  );
};
