import { PlanData, plans } from '@/models/plans';

const gaPremiumPurchase = (paidPlan: string, subscriptionId?: string) => {
  const planData = plans.find(
    (p) => paidPlan.toLowerCase() === p.name.toLowerCase()
  );

  const gaParams: any = {
    currency: 'USD',
    value: planData.monthlyPrice,
    items: [
      {
        item_id: planData.name,
        item_name: planData.name,
        price: planData.monthlyPrice,
        quantity: 1
      }
    ]
  };
  if (subscriptionId) gaParams.transaction_id = subscriptionId;
  window.gtag('event', 'purchase', gaParams);
};

const gaSignUp = (signupType: 'Google' | 'Email') => {
  window.gtag('event', 'sign_up', {
    method: signupType
  });
};

const gaLogin = (loginType: 'Google' | 'Email') => {
  window.gtag('event', 'login', {
    method: loginType
  });
};

const gaCheckout = (plan: PlanData) => {
  window.gtag('event', 'begin_checkout', {
    currency: 'USD',
    value: plan.monthlyPrice,
    items: [
      {
        item_id: plan.name,
        item_name: plan.name,
        price: plan.monthlyPrice,
        quantity: 1
      }
    ]
  });
};

export { gaSignUp, gaLogin, gaCheckout, gaPremiumPurchase };
