import { useEffect, useState } from 'react';

export type ScreenType = 'mobile' | 'tab' | 'web' | 'desktop';
export enum ScreenTypes {
  'mobile' = 'mobile',
  'tab' = 'tab',
  'web' = 'web',
  'desktop' = 'desktop'
}

export const screenBreakpoints = {
  mobile: 400,
  tab: 786,
  web: 1220,
  desktop: 1440
  // Add more screenBreakpoints as needed
};

export const useScreenType = (): ScreenType => {
  const [screenType, setScreenType] = useState<ScreenType>(ScreenTypes.web);

  const handleResize = (): void => {
    const { innerWidth } = window;
    if (innerWidth < screenBreakpoints.mobile) {
      setScreenType(ScreenTypes.mobile);
    } else if (innerWidth < screenBreakpoints.tab) {
      setScreenType(ScreenTypes.tab);
    } else if (innerWidth < screenBreakpoints.web) {
      setScreenType(ScreenTypes.web);
    } else {
      setScreenType(ScreenTypes.desktop);
    }
  };

  useEffect(() => {
    // Initial screen type check
    handleResize();
    // Attach event listener to window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return screenType;
};
